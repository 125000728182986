/*----------------------------------------------------------------------
    Template Name: Printco - Printing Company and Service Html Template
    Template URI: https://webtend.net/demo/html/printco/
    Author: WebTend
    Author URI:  https://webtend.net/
    Version: 1.0

    Note: This is Main Style CSS File. */
/*----------------------------------------------------------------------
	CSS INDEX
	----------------------

    01. Default Style
    02. Common Classes
    03. Repeat Style
    04. Padding Margin
    05. Custom Animation
    06. Main Header
    07. PopUp Search
    08. Hero Area
    09. Banner Area
    10. About Area
    11. What We Provide
    12. Services Area
    13. Counter Area
    14. Project Area
    15. CTA Area
    16. Testimonials
    17. Pricing Area
    18. Blog Area
    19. Shop Area
    20. All Widgets
    21. Category Area
    22. Offers Area
    23. Work Step
    24. FAQ Area
    25. Team Member
    26. Skills Area
    27. Contact Area
    28. 404 Error
    29. Main Footer */
/* -------------------------------------------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600&amp;display=swap");

/*******************************************************/
/******************* 01. Default Style *****************/
/*******************************************************/
* {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

body {
  /* color: #7e7e7e; */
  color: #3c3c3c;
  background: white;
  font-weight: 400;
  line-height: 32px;
  /* font-display: swap; */
  font-size: 18px;
  font-family: "Outfit", sans-serif;
}

a {
  color: #7e7e7e;
  cursor: pointer;
  outline: none;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none;
}

a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none;
}

a:hover {
  color: #0d6efd;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 12px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #000000;
}

h1 {
  line-height: 1.18;
  font-size: 60px;
}

h2 {
  font-size: 42px;
}

h3 {
  line-height: 1.5;
  font-size: 27px;
}

h4 {
  line-height: 1.35;
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  line-height: 1.4;
  font-size: 18px;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
  display: inline-block;
}

mark {
  background: transparent;
  text-decoration: underline;
}

header:after,
section:after,
footer:after {
  display: block;
  clear: both;
  content: "";
}

/*======= Input Styles =======*/
input,
select,
textarea,
.nice-select,
.form-control {
  width: 100%;
  height: auto;
  font-size: 18px;
  line-height: 1.5;
  border-radius: 5px;
  padding: 15px 42px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

textarea {
  display: inherit;
}

label {
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 5px;
  color: #7e7e7e;
}

.form-group {
  position: relative;
  margin-bottom: 30px;
}

.nice-select:after {
  width: 8px;
  height: 8px;
  right: 22px;
}

input:focus,
button:focus,
.nice-select:focus,
.form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #0d6efd;
}

input::-webkit-input-placeholder,
select::-webkit-input-placeholder,
.nice-select .current,
textarea::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
  font-weight: 500;
  color: #000000;
}

input:-ms-input-placeholder,
select:-ms-input-placeholder,
.nice-select .current,
textarea:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  font-weight: 500;
  color: #000000;
}

input::-ms-input-placeholder,
select::-ms-input-placeholder,
.nice-select .current,
textarea::-ms-input-placeholder,
.form-control::-ms-input-placeholder {
  font-weight: 500;
  color: #000000;
}

input::placeholder,
select::placeholder,
.nice-select .current,
textarea::placeholder,
.form-control::placeholder {
  font-weight: 500;
  color: #000000;
}

input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

input[type=checkbox],
input[type=radio] {
  height: auto;
  width: auto;
}

/*******************************************************/
/*****************  02. Common Classes *****************/
/*******************************************************/
.page-wrapper {
  position: relative;
  z-index: 9;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  min-width: 300px;
  background: #FFFFFF;
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 1200px) {
  .container-1570 {
    max-width: 1600px;
  }
}

@media only screen and (min-width: 1200px) {
  .container-1350 {
    max-width: 1380px;
  }
}

.row {
  --bs-gutter-x: 30px;
}

.no-gap {
  margin-left: 0;
  margin-right: 0;
}

.no-gap>div {
  padding-left: 0;
  padding-right: 0;
}

.small-gap {
  margin-left: -10px;
  margin-right: -10px;
}

.small-gap>div {
  padding-left: 10px;
  padding-right: 10px;
}

@media only screen and (min-width: 1400px) {
  .large-gap {
    margin-left: -40px;
    margin-right: -40px;
  }

  .large-gap>div {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media only screen and (max-width: 375px) {
  .col-small {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/** Section Title style **/
.sub-title {
  font-size: 20px;
  font-weight: 600;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #0d6efd;
  text-transform: capitalize;
}

@media only screen and (max-width: 575px) {
  .sub-title {
    font-size: 18px;
  }
}

.section-title h4 {
  line-height: 1.2;
  font-size: 42px;
  text-transform: capitalize;
}

@media only screen and (max-width: 767px) {
  .section-title h4 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 575px) {
  .section-title h4 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 375px) {
  .section-title h4 {
    font-size: 28px;
  }
}

/* Text White */
.text-white * {
  color: white;
}

.text-white * p,
.text-white * a,
.text-white * a i {
  color: #b8b8b8;
}

.text-white * h1,
.text-white * h2,
.text-white * h3,
.text-white * h4,
.text-white * h5,
.text-white * h6,
.text-white * h1 a,
.text-white * h2 a,
.text-white * h3 a,
.text-white * h4 a,
.text-white * h5 a,
.text-white * h6 a {
  color: white;
}

/** Button style **/
.theme-btn,
a.theme-btn {
  color: white;
  font-size: 16px;
  cursor: pointer;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  font-weight: 500;
  border-radius: 5px;
  text-align: center;
  padding: 9px 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-transform: capitalize;
  background: #0d6efd;
}

.theme-btn i,
a.theme-btn i {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-left: 10px;
}

.theme-btn:hover,
a.theme-btn:hover {
  background: black;
}

.theme-btn:hover i,
a.theme-btn:hover i {
  margin-left: 13px;
  margin-right: -3px;
}

.theme-btn.hover-two:hover,
a.theme-btn.hover-two:hover {
  background: white;
  color: #0d6efd;
}

.theme-btn.style-two,
a.theme-btn.style-two {
  background: rgb(255, 255, 255);
  color: #000000;
}

.theme-btn.style-two:hover,
a.theme-btn.style-two:hover {
  background: #0d6efd;
}

.theme-btn.style-three,
a.theme-btn.style-three {
  padding: 8px 29px;
  color: #000000;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.theme-btn.style-three:hover,
a.theme-btn.style-three:hover {
  color: white;
  background: #0d6efd;
  border-color: #0d6efd;
}

@media only screen and (max-width: 575px) {

  .theme-btn.style-three,
  a.theme-btn.style-three {
    padding: 6px 24px;
  }
}

@media only screen and (max-width: 575px) {

  .theme-btn,
  a.theme-btn {
    padding: 7px 25px;
  }
}

/* Read More */
.read-more {
  font-size: 16px;
  font-weight: 500;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-transform: capitalize;
  text-decoration: underline;
}

.read-more i {
  float: right;
  font-size: 12px;
  margin-top: 4px;
  margin-left: 7px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.read-more:hover {
  color: #0d6efd;
  text-decoration: underline;
}

.read-more:hover i {
  margin-left: 10px;
  color: #0d6efd;
}

/* List style One */
.list-style-one li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.list-style-one li:not(:last-child) {
  margin-bottom: 6px;
}

.list-style-one li:before {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  color: #43c1ab;
  content: '\f00c';
  margin-right: 15px;
  font-family: 'Font Awesome 5 Pro';
}

/* List style two */
.list-style-two li {
  color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
}

.list-style-two li:not(:last-child) {
  margin-bottom: 6px;
}

.list-style-two li:before {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  content: '\f058';
  margin-right: 13px;
  color: #0d6efd;
  font-family: 'Font Awesome 5 Pro';
}

/** Social Link One **/
.social-style-one {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.social-style-one a:not(:last-child) {
  margin-right: 28px;
}

/** Social Link two **/
.social-style-two {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.social-style-two a {
  width: 40px;
  height: 40px;
  background: white;
  color: #000000;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  -webkit-box-shadow: 10px 0 60px rgba(132, 132, 132, 0.5);
  box-shadow: 10px 0 60px rgba(132, 132, 132, 0.5);
}

.social-style-two a:not(:last-child) {
  margin-right: 10px;
}

.social-style-two a:hover {
  color: white;
  background: #0d6efd;
}

/*** Preloader style ** */
.preloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../images/preloader.gif);
}

/* Pagination */
.pagination {
  margin-left: -5px;
  margin-right: -5px;
}

.pagination li {
  margin: 10px 5px 0;
}

.pagination li a,
.pagination li .page-link {
  padding: 0;
  width: 50px;
  height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 700;
  line-height: 48px;
  border-radius: 50%;
  text-align: center;
  color: #000000;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 375px) {

  .pagination li a,
  .pagination li .page-link {
    width: 42px;
    height: 42px;
    font-size: 16px;
    line-height: 40px;
  }
}

.pagination li.active .page-link,
.pagination li:hover:not(.disabled) .page-link {
  color: white;
  background: #0d6efd;
  border-color: #0d6efd;
}

.pagination li:last-child .page-link,
.pagination li:first-child .page-link {
  border-radius: 50%;
}

/* Rating */
.newslick .slick-prev,
.newslick .slick-next,
.projectabc .slick-prev,
.projectabc .slick-next,
.textimon .slick-prev,
.textimon .slick-next,
.servicespagetabsli .slick-prev,
.servicespagetabsli .slick-next {
  display: none !important;
}

.newslick .slick-dots {
  display: flex !important;
}

.ratting {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.ratting i {
  color: #0d6efd;
}

.ratting i:not(:last-child) {
  margin-right: 10px;
}

.textimon {
  margin-top: 100px;
}

/*** Slick Dots ***/
.slick-arrow {
  font-size: 30px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  color: #000000;
  background: transparent;
}

.slick-arrow:focus,
.slick-arrow:hover {
  color: #0d6efd;
}

/*** Slick Dots ***/
.slick-dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.slick-dots li {
  position: relative;
  cursor: pointer;
  margin: 8px;
  width: 7px;
  height: 7px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-radius: 50%;
  background: #0d6efd;
}

.slick-dots li button {
  opacity: 0;
  height: 0;
}

.slick-dots li:before {
  content: '';
  width: 0;
  height: 0;
  left: 50%;
  top: 50%;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-radius: 50%;
  position: absolute;
  border: 1px solid #0d6efd;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slick-dots li.slick-active:before {
  width: 21px;
  height: 21px;
}

/*** Scroll Top style ***/
.back-to-top {
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 99;
  color: white;
  display: none;
  cursor: pointer;
  font-size: 20px;
  width: 50px;
  height: 50px;
  background: -webkit-gradient(linear, left top, right top, from(#ff7e5f), to(#feb47b));
  background: -webkit-linear-gradient(left, #ff7e5f, #feb47b);
  background: -o-linear-gradient(left, #ff7e5f, #feb47b);
  background: linear-gradient(90deg, #0d6efd, #0dcaf0);
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 991px) {
  .back-to-top {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }

  .textimon {
    margin-top: auto;
  }
}

/* Video Play Btn */
.video-play {
  color: white;
  font-size: 12px;
  display: inline-block;
  width: 60px;
  height: 60px;
  background: -webkit-gradient(linear, left top, right top, from(#ff7e5f), to(#feb47b));
  background: -webkit-linear-gradient(left, #ff7e5f, #feb47b);
  background: -o-linear-gradient(left, #ff7e5f, #feb47b);
  background: linear-gradient(90deg, #0d6efd, #0dcaf0);
  line-height: 60px;
  border-radius: 50%;
  text-align: center;
}

.video-play:hover {
  color: white;
}

/* Tab Btn style one */
.tab-btns-one .nav-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 0;
  text-align: left;
  padding: 0 0 25px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
  background: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 575px) {
  .tab-btns-one .nav-link {
    padding-bottom: 10px;
    margin-bottom: 15px;
  }
}

.tab-btns-one .nav-link i {
  font-size: 45px;
  margin-right: 20px;
  color: #0d6efd;
}

@media only screen and (max-width: 575px) {
  .tab-btns-one .nav-link i {
    font-size: 35px;
  }
}

.tab-btns-one .nav-link .title {
  font-size: 27px;
  margin-bottom: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

@media only screen and (max-width: 575px) {
  .tab-btns-one .nav-link .title {
    font-size: 22px;
  }
}

.tab-btns-one .nav-link.active {
  background: transparent;
  border-bottom-color: black;
}

.tab-btns-one .nav-link.active .title {
  color: #0d6efd;
}

.tab-btns-one li {
  margin-bottom: 10px;
}

.tab-btns-one li:not(:last-child) {
  margin-right: 12px;
}

.tab-btns-one li button {
  font-size: 18px;
  padding: 10px 18px;
  color: #7e7e7e;
  border-radius: 3px;
  background: #f8f3ff;
  z-index: 1;
  position: relative;
}

.tab-btns-one li button::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0;
  background: -webkit-gradient(linear, right top, left top, from(#FB5D4A), color-stop(117.45%, #FF9B07));
  background: -webkit-linear-gradient(right, #FB5D4A 0%, #FF9B07 117.45%);
  background: -o-linear-gradient(right, #FB5D4A 0%, #FF9B07 117.45%);
  background: linear-gradient(270deg, #FB5D4A 0%, #FF9B07 117.45%);
}

@media only screen and (max-width: 575px) {
  .tab-btns-one li button {
    font-size: 16px;
    padding: 8px 15px;
  }
}

.tab-btns-one li button:before {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-radius: 3px;
}

.tab-btns-one li button:hover {
  color: #7e7e7e;
}

.tab-btns-one li button.active {
  color: white;
}

.tab-btns-one li button.active:before {
  opacity: 1;
}

/* Position */
.rel {
  position: relative;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

.z-5 {
  z-index: 5;
}

.overlay {
  z-index: 1;
  position: relative;
}

.overlay::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.75;
  background: #000000;
}

.shape {
  z-index: -1;
  position: absolute;
}

/* Backgruond Size */
.bgs-cover {
  background-size: cover;
  background-position: center;
}

/* Color + Background */
.bgc-black {
  background-color: #000000;
}

.bgc-lighter {
  background-color: #f8f3ff;
}

.bgc-primary {
  background-color: #0d6efd;
}

.bgc-gradient {
  background: -webkit-gradient(linear, left top, right top, from(#ff7e5f), to(#feb47b));
  background: -webkit-linear-gradient(left, #ff7e5f, #feb47b);
  background: -o-linear-gradient(left, #ff7e5f, #feb47b);
  background: linear-gradient(90deg, #0d6efd, #0dcaf0);
}

.color-primary {
  color: #0d6efd;
}

/* Border Radius */
.br-5 {
  border-radius: 5px;
}

.br-10 {
  border-radius: 10px;
}

.br-15 {
  border-radius: 15px;
}

.br-20 {
  border-radius: 20px;
}

.br-25 {
  border-radius: 25px;
}

.br-30 {
  border-radius: 30px;
}

/*******************************************************/
/******************* 03. Repeat Style ******************/
/*******************************************************/
h1,
h2,
h3,
h4,
h5,
h6,
.tab-btns-one .nav-link .title {
  font-weight: 600;
  color: #000000;
  font-family: "Outfit", sans-serif;
}

/*******************************************************/
/************** 04. Padding Margin Spacing *************/
/*******************************************************/
/* Padding Around */
.p-5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-25 {
  padding: 25px;
}

.p-30 {
  padding: 30px;
}

.p-35 {
  padding: 35px;
}

.p-40 {
  padding: 40px;
}

.p-45 {
  padding: 45px;
}

.p-50 {
  padding: 50px;
}

.p-55 {
  padding: 55px;
}

.p-60 {
  padding: 60px;
}

.p-65 {
  padding: 65px;
}

.p-70 {
  padding: 70px;
}

.p-75 {
  padding: 75px;
}

.p-80 {
  padding: 80px;
}

.p-85 {
  padding: 85px;
}

.p-90 {
  padding: 90px;
}

.p-95 {
  padding: 95px;
}

.p-100 {
  padding: 100px;
}

.p-105 {
  padding: 105px;
}

.p-110 {
  padding: 110px;
}

.p-115 {
  padding: 115px;
}

.p-120 {
  padding: 120px;
}

.p-125 {
  padding: 125px;
}

.p-130 {
  padding: 130px;
}

.p-135 {
  padding: 135px;
}

.p-140 {
  padding: 140px;
}

.p-145 {
  padding: 145px;
}

.p-150 {
  padding: 150px;
}

.p-155 {
  padding: 155px;
}

.p-160 {
  padding: 160px;
}

.p-165 {
  padding: 165px;
}

.p-170 {
  padding: 170px;
}

.p-175 {
  padding: 175px;
}

.p-180 {
  padding: 180px;
}

.p-185 {
  padding: 185px;
}

.p-190 {
  padding: 190px;
}

.p-195 {
  padding: 195px;
}

.p-200 {
  padding: 200px;
}

.p-205 {
  padding: 205px;
}

.p-210 {
  padding: 210px;
}

.p-215 {
  padding: 215px;
}

.p-220 {
  padding: 220px;
}

.p-225 {
  padding: 225px;
}

.p-230 {
  padding: 230px;
}

.p-235 {
  padding: 235px;
}

.p-240 {
  padding: 240px;
}

.p-245 {
  padding: 245px;
}

.p-250 {
  padding: 250px;
}

/* Padding Top */
.pt-5,
.py-5 {
  padding-top: 5px !important;
}

.pt-10,
.py-10 {
  padding-top: 10px;
}

.pt-15,
.py-15 {
  padding-top: 15px;
}

.pt-20,
.py-20 {
  padding-top: 20px;
}

.pt-25,
.py-25 {
  padding-top: 25px;
}

.pt-30,
.py-30 {
  padding-top: 30px;
}

.pt-35,
.py-35 {
  padding-top: 35px;
}

.pt-40,
.py-40 {
  padding-top: 40px;
}

.pt-45,
.py-45 {
  padding-top: 45px;
}

.pt-50,
.py-50 {
  padding-top: 50px;
}

.pt-55,
.py-55 {
  padding-top: 55px;
}

.pt-60,
.py-60 {
  padding-top: 60px;
}

.pt-65,
.py-65 {
  padding-top: 157px;
}

.pt-70,
.py-70 {
  padding-top: 70px;
}

.pt-75,
.py-75 {
  padding-top: 75px;
}

.pt-80,
.py-80 {
  padding-top: 80px;
}

.pt-85,
.py-85 {
  padding-top: 85px;
}

.pt-90,
.py-90 {
  padding-top: 90px;
}

.pt-95,
.py-95 {
  padding-top: 95px;
}

.pt-100,
.py-100 {
  padding-top: 100px;
}

.pt-105,
.py-105 {
  padding-top: 105px;
}

.pt-110,
.py-110 {
  padding-top: 110px;
}

.pt-115,
.py-115 {
  padding-top: 115px;
}

.pt-120,
.py-120 {
  padding-top: 120px;
}

.pt-125,
.py-125 {
  padding-top: 125px;
}

.pt-130,
.py-130 {
  padding-top: 130px;
}

.pt-135,
.py-135 {
  padding-top: 135px;
}

.pt-140,
.py-140 {
  padding-top: 140px;
}

.pt-145,
.py-145 {
  padding-top: 145px;
}

.pt-150,
.py-150 {
  padding-top: 150px;
}

.pt-155,
.py-155 {
  padding-top: 155px;
}

.pt-160,
.py-160 {
  padding-top: 160px;
}

.pt-165,
.py-165 {
  padding-top: 165px;
}

.pt-170,
.py-170 {
  padding-top: 170px;
}

.pt-175,
.py-175 {
  padding-top: 175px;
}

.pt-180,
.py-180 {
  padding-top: 180px;
}

.pt-185,
.py-185 {
  padding-top: 185px;
}

.pt-190,
.py-190 {
  padding-top: 190px;
}

.pt-195,
.py-195 {
  padding-top: 195px;
}

.pt-200,
.py-200 {
  padding-top: 200px;
}

.pt-205,
.py-205 {
  padding-top: 205px;
}

.pt-210,
.py-210 {
  padding-top: 210px;
}

.pt-215,
.py-215 {
  padding-top: 215px;
}

.pt-220,
.py-220 {
  padding-top: 220px;
}

.pt-225,
.py-225 {
  padding-top: 225px;
}

.pt-230,
.py-230 {
  padding-top: 230px;
}

.pt-235,
.py-235 {
  padding-top: 235px;
}

.pt-240,
.py-240 {
  padding-top: 240px;
}

.pt-245,
.py-245 {
  padding-top: 245px;
}

.pt-250,
.py-250 {
  padding-top: 250px;
}

/* Padding Bottom */
.pb-5,
.py-5 {
  padding-bottom: 5px !important;
}

.pb-10,
.py-10 {
  padding-bottom: 10px;
}

.pb-15,
.py-15 {
  padding-bottom: 15px;
}

.pb-20,
.py-20 {
  padding-bottom: 20px;
}

.pb-25,
.py-25 {
  padding-bottom: 25px;
}

.pb-30,
.py-30 {
  padding-bottom: 30px;
}

.pb-35,
.py-35 {
  padding-bottom: 35px;
}

.pb-40,
.py-40 {
  padding-bottom: 40px;
}

.pb-45,
.py-45 {
  padding-bottom: 45px;
}

.pb-50,
.py-50 {
  padding-bottom: 50px;
}

.pb-55,
.py-55 {
  padding-bottom: 55px;
}

.pb-60,
.py-60 {
  padding-bottom: 60px;
}

.pb-65,
.py-65 {
  padding-bottom: 65px;
}

.pb-70,
.py-70 {
  padding-bottom: 70px;
}

.pb-75,
.py-75 {
  padding-bottom: 75px;
}

.pb-80,
.py-80 {
  padding-bottom: 80px;
}

.pb-85,
.py-85 {
  padding-bottom: 85px;
}

.pb-90,
.py-90 {
  padding-bottom: 90px;
}

.pb-95,
.py-95 {
  padding-bottom: 95px;
}

.pb-100,
.py-100 {
  padding-bottom: 100px;
}

.pb-105,
.py-105 {
  padding-bottom: 105px;
}

.pb-110,
.py-110 {
  padding-bottom: 110px;
}

.pb-115,
.py-115 {
  padding-bottom: 115px;
}

.pb-120,
.py-120 {
  padding-bottom: 120px;
}

.pb-125,
.py-125 {
  padding-bottom: 125px;
}

.pb-130,
.py-130 {
  padding-bottom: 130px;
}

.pb-135,
.py-135 {
  padding-bottom: 135px;
}

.pb-140,
.py-140 {
  padding-bottom: 140px;
}

.pb-145,
.py-145 {
  padding-bottom: 145px;
}

.pb-150,
.py-150 {
  padding-bottom: 150px;
}

.pb-155,
.py-155 {
  padding-bottom: 155px;
}

.pb-160,
.py-160 {
  padding-bottom: 160px;
}

.pb-165,
.py-165 {
  padding-bottom: 165px;
}

.pb-170,
.py-170 {
  padding-bottom: 170px;
}

.pb-175,
.py-175 {
  padding-bottom: 175px;
}

.pb-180,
.py-180 {
  padding-bottom: 180px;
}

.pb-185,
.py-185 {
  padding-bottom: 185px;
}

.pb-190,
.py-190 {
  padding-bottom: 190px;
}

.pb-195,
.py-195 {
  padding-bottom: 195px;
}

.pb-200,
.py-200 {
  padding-bottom: 200px;
}

.pb-205,
.py-205 {
  padding-bottom: 205px;
}

.pb-210,
.py-210 {
  padding-bottom: 210px;
}

.pb-215,
.py-215 {
  padding-bottom: 215px;
}

.pb-220,
.py-220 {
  padding-bottom: 220px;
}

.pb-225,
.py-225 {
  padding-bottom: 225px;
}

.pb-230,
.py-230 {
  padding-bottom: 230px;
}

.pb-235,
.py-235 {
  padding-bottom: 235px;
}

.pb-240,
.py-240 {
  padding-bottom: 240px;
}

.pb-245,
.py-245 {
  padding-bottom: 245px;
}

.pb-250,
.py-250 {
  padding-bottom: 250px;
}

/* Margin Around */
.m-5 {
  margin: 5px !important;
}

.m-10 {
  margin: 10px;
}

.m-15 {
  margin: 15px;
}

.m-20 {
  margin: 20px;
}

.m-25 {
  margin: 25px;
}

.m-30 {
  margin: 30px;
}

.m-35 {
  margin: 35px;
}

.m-40 {
  margin: 40px;
}

.m-45 {
  margin: 45px;
}

.m-50 {
  margin: 50px;
}

.m-55 {
  margin: 55px;
}

.m-60 {
  margin: 60px;
}

.m-65 {
  margin: 65px;
}

.m-70 {
  margin: 70px;
}

.m-75 {
  margin: 75px;
}

.m-80 {
  margin: 80px;
}

.m-85 {
  margin: 85px;
}

.m-90 {
  margin: 90px;
}

.m-95 {
  margin: 95px;
}

.m-100 {
  margin: 100px;
}

.m-105 {
  margin: 105px;
}

.m-110 {
  margin: 110px;
}

.m-115 {
  margin: 115px;
}

.m-120 {
  margin: 120px;
}

.m-125 {
  margin: 125px;
}

.m-130 {
  margin: 130px;
}

.m-135 {
  margin: 135px;
}

.m-140 {
  margin: 140px;
}

.m-145 {
  margin: 145px;
}

.m-150 {
  margin: 150px;
}

.m-155 {
  margin: 155px;
}

.m-160 {
  margin: 160px;
}

.m-165 {
  margin: 165px;
}

.m-170 {
  margin: 170px;
}

.m-175 {
  margin: 175px;
}

.m-180 {
  margin: 180px;
}

.m-185 {
  margin: 185px;
}

.m-190 {
  margin: 190px;
}

.m-195 {
  margin: 195px;
}

.m-200 {
  margin: 200px;
}

.m-205 {
  margin: 205px;
}

.m-210 {
  margin: 210px;
}

.m-215 {
  margin: 215px;
}

.m-220 {
  margin: 220px;
}

.m-225 {
  margin: 225px;
}

.m-230 {
  margin: 230px;
}

.m-235 {
  margin: 235px;
}

.m-240 {
  margin: 240px;
}

.m-245 {
  margin: 245px;
}

.m-250 {
  margin: 250px;
}

/* Margin Top */
.mt-5,
.my-5 {
  margin-top: 5px !important;
}

.mt-10,
.my-10 {
  margin-top: 10px;
}

.mt-15,
.my-15 {
  margin-top: 15px;
}

.mt-20,
.my-20 {
  margin-top: 20px;
}

.mt-25,
.my-25 {
  margin-top: 25px;
}

.mt-30,
.my-30 {
  margin-top: 30px;
}

.mt-35,
.my-35 {
  margin-top: 35px;
}

.mt-40,
.my-40 {
  margin-top: 40px;
}

.mt-45,
.my-45 {
  margin-top: 45px;
}

.mt-50,
.my-50 {
  margin-top: 50px;
}

.mt-55,
.my-55 {
  margin-top: 55px;
}

.mt-60,
.my-60 {
  margin-top: 60px;
}

.mt-65,
.my-65 {
  margin-top: 65px;
}

.mt-70,
.my-70 {
  margin-top: 70px;
}

.mt-75,
.my-75 {
  margin-top: 75px;
}

.mt-80,
.my-80 {
  margin-top: 80px;
}

.mt-85,
.my-85 {
  margin-top: 85px;
}

.mt-90,
.my-90 {
  margin-top: 90px;
}

.mt-95,
.my-95 {
  margin-top: 95px;
}

.mt-100,
.my-100 {
  margin-top: 100px;
}

.mt-105,
.my-105 {
  margin-top: 105px;
}

.mt-110,
.my-110 {
  margin-top: 110px;
}

.mt-115,
.my-115 {
  margin-top: 115px;
}

.mt-120,
.my-120 {
  margin-top: 120px;
}

.mt-125,
.my-125 {
  margin-top: 125px;
}

.mt-130,
.my-130 {
  margin-top: 130px;
}

.mt-135,
.my-135 {
  margin-top: 135px;
}

.mt-140,
.my-140 {
  margin-top: 140px;
}

.mt-145,
.my-145 {
  margin-top: 145px;
}

.mt-150,
.my-150 {
  margin-top: 150px;
}

.mt-155,
.my-155 {
  margin-top: 155px;
}

.mt-160,
.my-160 {
  margin-top: 160px;
}

.mt-165,
.my-165 {
  margin-top: 165px;
}

.mt-170,
.my-170 {
  margin-top: 170px;
}

.mt-175,
.my-175 {
  margin-top: 175px;
}

.mt-180,
.my-180 {
  margin-top: 180px;
}

.mt-185,
.my-185 {
  margin-top: 185px;
}

.mt-190,
.my-190 {
  margin-top: 190px;
}

.mt-195,
.my-195 {
  margin-top: 195px;
}

.mt-200,
.my-200 {
  margin-top: 200px;
}

.mt-205,
.my-205 {
  margin-top: 205px;
}

.mt-210,
.my-210 {
  margin-top: 210px;
}

.mt-215,
.my-215 {
  margin-top: 215px;
}

.mt-220,
.my-220 {
  margin-top: 220px;
}

.mt-225,
.my-225 {
  margin-top: 225px;
}

.mt-230,
.my-230 {
  margin-top: 230px;
}

.mt-235,
.my-235 {
  margin-top: 235px;
}

.mt-240,
.my-240 {
  margin-top: 240px;
}

.mt-245,
.my-245 {
  margin-top: 245px;
}

.mt-250,
.my-250 {
  margin-top: 250px;
}

/* Margin Bottom */
.mb-5,
.my-5 {
  margin-bottom: 5px !important;
}

.mb-10,
.my-10 {
  margin-bottom: 10px;
}

.mb-15,
.my-15 {
  margin-bottom: 15px;
}

.mb-20,
.my-20 {
  margin-bottom: 20px;
}

.mb-25,
.my-25 {
  margin-bottom: 25px;
}

.mb-30,
.my-30 {
  margin-bottom: 30px;
}

.mb-35,
.my-35 {
  margin-bottom: 35px;
}

.mb-40,
.my-40 {
  margin-bottom: 40px;
}

.mb-45,
.my-45 {
  margin-bottom: 45px;
}

.mb-50,
.my-50 {
  margin-bottom: 50px;
}

.mb-55,
.my-55 {
  margin-bottom: 55px;
}

.mb-60,
.my-60 {
  margin-bottom: 60px;
}

.mb-65,
.my-65 {
  margin-bottom: 65px;
}

.mb-70,
.my-70 {
  margin-bottom: 70px;
}

.mb-75,
.my-75 {
  margin-bottom: 75px;
}

.mb-80,
.my-80 {
  margin-bottom: 80px;
}

.mb-85,
.my-85 {
  margin-bottom: 85px;
}

.mb-90,
.my-90 {
  margin-bottom: 90px;
}

.mb-95,
.my-95 {
  margin-bottom: 95px;
}

.mb-100,
.my-100 {
  margin-bottom: 100px;
}

.mb-105,
.my-105 {
  margin-bottom: 105px;
}

.mb-110,
.my-110 {
  margin-bottom: 110px;
}

.mb-115,
.my-115 {
  margin-bottom: 115px;
}

.mb-120,
.my-120 {
  margin-bottom: 120px;
}

.mb-125,
.my-125 {
  margin-bottom: 125px;
}

.mb-130,
.my-130 {
  margin-bottom: 130px;
}

.mb-135,
.my-135 {
  margin-bottom: 135px;
}

.mb-140,
.my-140 {
  margin-bottom: 140px;
}

.mb-145,
.my-145 {
  margin-bottom: 145px;
}

.mb-150,
.my-150 {
  margin-bottom: 150px;
}

.mb-155,
.my-155 {
  margin-bottom: 155px;
}

.mb-160,
.my-160 {
  margin-bottom: 160px;
}

.mb-165,
.my-165 {
  margin-bottom: 165px;
}

.mb-170,
.my-170 {
  margin-bottom: 170px;
}

.mb-175,
.my-175 {
  margin-bottom: 175px;
}

.mb-180,
.my-180 {
  margin-bottom: 180px;
}

.mb-185,
.my-185 {
  margin-bottom: 185px;
}

.mb-190,
.my-190 {
  margin-bottom: 190px;
}

.mb-195,
.my-195 {
  margin-bottom: 195px;
}

.mb-200,
.my-200 {
  margin-bottom: 200px;
}

.mb-205,
.my-205 {
  margin-bottom: 205px;
}

.mb-210,
.my-210 {
  margin-bottom: 210px;
}

.mb-215,
.my-215 {
  margin-bottom: 215px;
}

.mb-220,
.my-220 {
  margin-bottom: 220px;
}

.mb-225,
.my-225 {
  margin-bottom: 225px;
}

.mb-230,
.my-230 {
  margin-bottom: 230px;
}

.mb-235,
.my-235 {
  margin-bottom: 235px;
}

.mb-240,
.my-240 {
  margin-bottom: 240px;
}

.mb-245,
.my-245 {
  margin-bottom: 245px;
}

.mb-250,
.my-250 {
  margin-bottom: 250px;
}

/* Responsive Padding Margin */
@media only screen and (max-width: 991px) {

  /* Padding Around */
  .rp-0 {
    padding: 0px !important;
  }

  .rp-5 {
    padding: 5px !important;
  }

  .rp-10 {
    padding: 10px;
  }

  .rp-15 {
    padding: 15px;
  }

  .rp-20 {
    padding: 20px;
  }

  .rp-25 {
    padding: 25px;
  }

  .rp-30 {
    padding: 30px;
  }

  .rp-35 {
    padding: 35px;
  }

  .rp-40 {
    padding: 40px;
  }

  .rp-45 {
    padding: 45px;
  }

  .rp-50 {
    padding: 50px;
  }

  .rp-55 {
    padding: 55px;
  }

  .rp-60 {
    padding: 60px;
  }

  .rp-65 {
    padding: 65px;
  }

  .rp-70 {
    padding: 70px;
  }

  .rp-75 {
    padding: 75px;
  }

  .rp-80 {
    padding: 80px;
  }

  .rp-85 {
    padding: 85px;
  }

  .rp-90 {
    padding: 90px;
  }

  .rp-95 {
    padding: 95px;
  }

  .rp-100 {
    padding: 100px;
  }

  .rp-105 {
    padding: 105px;
  }

  .rp-110 {
    padding: 110px;
  }

  .rp-115 {
    padding: 115px;
  }

  .rp-120 {
    padding: 120px;
  }

  .rp-125 {
    padding: 125px;
  }

  .rp-130 {
    padding: 130px;
  }

  .rp-135 {
    padding: 135px;
  }

  .rp-140 {
    padding: 140px;
  }

  .rp-145 {
    padding: 145px;
  }

  .rp-150 {
    padding: 150px;
  }

  /* Padding Top */
  .rpt-0,
  .rpy-0 {
    padding-top: 0px !important;
  }

  .rpt-5,
  .rpy-5 {
    padding-top: 5px !important;
  }

  .rpt-10,
  .rpy-10 {
    padding-top: 10px;
  }

  .rpt-15,
  .rpy-15 {
    padding-top: 15px;
  }

  .rpt-20,
  .rpy-20 {
    padding-top: 20px;
  }

  .rpt-25,
  .rpy-25 {
    padding-top: 25px;
  }

  .rpt-30,
  .rpy-30 {
    padding-top: 30px;
  }

  .rpt-35,
  .rpy-35 {
    padding-top: 35px;
  }

  .rpt-40,
  .rpy-40 {
    padding-top: 40px;
  }

  .rpt-45,
  .rpy-45 {
    padding-top: 45px;
  }

  .rpt-50,
  .rpy-50 {
    padding-top: 50px;
  }

  .rpt-55,
  .rpy-55 {
    padding-top: 55px;
  }

  .rpt-60,
  .rpy-60 {
    padding-top: 60px;
  }

  .rpt-65,
  .rpy-65 {
    padding-top: 65px;
  }

  .rpt-70,
  .rpy-70 {
    padding-top: 70px;
  }

  .rpt-75,
  .rpy-75 {
    padding-top: 75px;
  }

  .rpt-80,
  .rpy-80 {
    padding-top: 80px;
  }

  .rpt-85,
  .rpy-85 {
    padding-top: 85px;
  }

  .rpt-90,
  .rpy-90 {
    padding-top: 90px;
  }

  .rpt-95,
  .rpy-95 {
    padding-top: 95px;
  }

  .rpt-100,
  .rpy-100 {
    padding-top: 100px;
  }

  .rpt-105,
  .rpy-105 {
    padding-top: 105px;
  }

  .rpt-110,
  .rpy-110 {
    padding-top: 110px;
  }

  .rpt-115,
  .rpy-115 {
    padding-top: 115px;
  }

  .rpt-120,
  .rpy-120 {
    padding-top: 120px;
  }

  .rpt-125,
  .rpy-125 {
    padding-top: 125px;
  }

  .rpt-130,
  .rpy-130 {
    padding-top: 130px;
  }

  .rpt-135,
  .rpy-135 {
    padding-top: 135px;
  }

  .rpt-140,
  .rpy-140 {
    padding-top: 140px;
  }

  .rpt-145,
  .rpy-145 {
    padding-top: 145px;
  }

  .rpt-150,
  .rpy-150 {
    padding-top: 150px;
  }

  /* Padding Bottom */
  .rpb-0,
  .rpy-0 {
    padding-bottom: 0px !important;
  }

  .rpb-5,
  .rpy-5 {
    padding-bottom: 5px !important;
  }

  .rpb-10,
  .rpy-10 {
    padding-bottom: 10px;
  }

  .rpb-15,
  .rpy-15 {
    padding-bottom: 15px;
  }

  .rpb-20,
  .rpy-20 {
    padding-bottom: 20px;
  }

  .rpb-25,
  .rpy-25 {
    padding-bottom: 25px;
  }

  .rpb-30,
  .rpy-30 {
    padding-bottom: 30px;
  }

  .rpb-35,
  .rpy-35 {
    padding-bottom: 35px;
  }

  .rpb-40,
  .rpy-40 {
    padding-bottom: 40px;
  }

  .rpb-45,
  .rpy-45 {
    padding-bottom: 45px;
  }

  .rpb-50,
  .rpy-50 {
    padding-bottom: 50px;
  }

  .rpb-55,
  .rpy-55 {
    padding-bottom: 55px;
  }

  .rpb-60,
  .rpy-60 {
    padding-bottom: 60px;
  }

  .rpb-65,
  .rpy-65 {
    padding-bottom: 65px;
  }

  .rpb-70,
  .rpy-70 {
    padding-bottom: 70px;
  }

  .rpb-75,
  .rpy-75 {
    padding-bottom: 75px;
  }

  .rpb-80,
  .rpy-80 {
    padding-bottom: 80px;
  }

  .rpb-85,
  .rpy-85 {
    padding-bottom: 85px;
  }

  .rpb-90,
  .rpy-90 {
    padding-bottom: 90px;
  }

  .rpb-95,
  .rpy-95 {
    padding-bottom: 95px;
  }

  .rpb-100,
  .rpy-100 {
    padding-bottom: 100px;
  }

  .rpb-105,
  .rpy-105 {
    padding-bottom: 105px;
  }

  .rpb-110,
  .rpy-110 {
    padding-bottom: 110px;
  }

  .rpb-115,
  .rpy-115 {
    padding-bottom: 115px;
  }

  .rpb-120,
  .rpy-120 {
    padding-bottom: 120px;
  }

  .rpb-125,
  .rpy-125 {
    padding-bottom: 125px;
  }

  .rpb-130,
  .rpy-130 {
    padding-bottom: 130px;
  }

  .rpb-135,
  .rpy-135 {
    padding-bottom: 135px;
  }

  .rpb-140,
  .rpy-140 {
    padding-bottom: 140px;
  }

  .rpb-145,
  .rpy-145 {
    padding-bottom: 145px;
  }

  .rpb-150,
  .rpy-150 {
    padding-bottom: 150px;
  }

  /* Margin Around */
  .rm-0 {
    margin: 0px !important;
  }

  .rm-5 {
    margin: 5px !important;
  }

  .rm-10 {
    margin: 10px;
  }

  .rm-15 {
    margin: 15px;
  }

  .rm-20 {
    margin: 20px;
  }

  .rm-25 {
    margin: 25px;
  }

  .rm-30 {
    margin: 30px;
  }

  .rm-35 {
    margin: 35px;
  }

  .rm-40 {
    margin: 40px;
  }

  .rm-45 {
    margin: 45px;
  }

  .rm-50 {
    margin: 50px;
  }

  .rm-55 {
    margin: 55px;
  }

  .rm-60 {
    margin: 60px;
  }

  .rm-65 {
    margin: 65px;
  }

  .rm-70 {
    margin: 70px;
  }

  .rm-75 {
    margin: 75px;
  }

  .rm-80 {
    margin: 80px;
  }

  .rm-85 {
    margin: 85px;
  }

  .rm-90 {
    margin: 90px;
  }

  .rm-95 {
    margin: 95px;
  }

  .rm-100 {
    margin: 100px;
  }

  .rm-105 {
    margin: 105px;
  }

  .rm-110 {
    margin: 110px;
  }

  .rm-115 {
    margin: 115px;
  }

  .rm-120 {
    margin: 120px;
  }

  .rm-125 {
    margin: 125px;
  }

  .rm-130 {
    margin: 130px;
  }

  .rm-135 {
    margin: 135px;
  }

  .rm-140 {
    margin: 140px;
  }

  .rm-145 {
    margin: 145px;
  }

  .rm-150 {
    margin: 150px;
  }

  /* Margin Top */
  .rmt-0,
  .rmy-0 {
    margin-top: 0px !important;
  }

  .rmt-5,
  .rmy-5 {
    margin-top: 5px !important;
  }

  .rmt-10,
  .rmy-10 {
    margin-top: 10px;
  }

  .rmt-15,
  .rmy-15 {
    margin-top: 15px;
  }

  .rmt-20,
  .rmy-20 {
    margin-top: 20px;
  }

  .rmt-25,
  .rmy-25 {
    margin-top: 25px;
  }

  .rmt-30,
  .rmy-30 {
    margin-top: 30px;
  }

  .rmt-35,
  .rmy-35 {
    margin-top: 35px;
  }

  .rmt-40,
  .rmy-40 {
    margin-top: 40px;
  }

  .rmt-45,
  .rmy-45 {
    margin-top: 45px;
  }

  .rmt-50,
  .rmy-50 {
    margin-top: 50px;
  }

  .rmt-55,
  .rmy-55 {
    margin-top: 55px;
  }

  .rmt-60,
  .rmy-60 {
    margin-top: 60px;
  }

  .rmt-65,
  .rmy-65 {
    margin-top: 65px;
  }

  .rmt-70,
  .rmy-70 {
    margin-top: 70px;
  }

  .rmt-75,
  .rmy-75 {
    margin-top: 75px;
  }

  .rmt-80,
  .rmy-80 {
    margin-top: 80px;
  }

  .rmt-85,
  .rmy-85 {
    margin-top: 85px;
  }

  .rmt-90,
  .rmy-90 {
    margin-top: 90px;
  }

  .rmt-95,
  .rmy-95 {
    margin-top: 95px;
  }

  .rmt-100,
  .rmy-100 {
    margin-top: 100px;
  }

  .rmt-105,
  .rmy-105 {
    margin-top: 105px;
  }

  .rmt-110,
  .rmy-110 {
    margin-top: 110px;
  }

  .rmt-115,
  .rmy-115 {
    margin-top: 115px;
  }

  .rmt-120,
  .rmy-120 {
    margin-top: 120px;
  }

  .rmt-125,
  .rmy-125 {
    margin-top: 125px;
  }

  .rmt-130,
  .rmy-130 {
    margin-top: 130px;
  }

  .rmt-135,
  .rmy-135 {
    margin-top: 135px;
  }

  .rmt-140,
  .rmy-140 {
    margin-top: 140px;
  }

  .rmt-145,
  .rmy-145 {
    margin-top: 145px;
  }

  .rmt-150,
  .rmy-150 {
    margin-top: 150px;
  }

  /* Margin Bottom */
  .rmb-0,
  .rmy-0 {
    margin-bottom: 0px !important;
  }

  .rmb-5,
  .rmy-5 {
    margin-bottom: 5px !important;
  }

  .rmb-10,
  .rmy-10 {
    margin-bottom: 10px;
  }

  .rmb-15,
  .rmy-15 {
    margin-bottom: 15px;
  }

  .rmb-20,
  .rmy-20 {
    margin-bottom: 20px;
  }

  .rmb-25,
  .rmy-25 {
    margin-bottom: 25px;
  }

  .rmb-30,
  .rmy-30 {
    margin-bottom: 30px;
  }

  .rmb-35,
  .rmy-35 {
    margin-bottom: 35px;
  }

  .rmb-40,
  .rmy-40 {
    margin-bottom: 40px;
  }

  .rmb-45,
  .rmy-45 {
    margin-bottom: 45px;
  }

  .rmb-50,
  .rmy-50 {
    margin-bottom: 50px;
  }

  .rmb-55,
  .rmy-55 {
    margin-bottom: 55px;
  }

  .rmb-60,
  .rmy-60 {
    margin-bottom: 60px;
  }

  .rmb-65,
  .rmy-65 {
    margin-bottom: 65px;
  }

  .rmb-70,
  .rmy-70 {
    margin-bottom: 70px;
  }

  .rmb-75,
  .rmy-75 {
    margin-bottom: 75px;
  }

  .rmb-80,
  .rmy-80 {
    margin-bottom: 80px;
  }

  .rmb-85,
  .rmy-85 {
    margin-bottom: 85px;
  }

  .rmb-90,
  .rmy-90 {
    margin-bottom: 90px;
  }

  .rmb-95,
  .rmy-95 {
    margin-bottom: 95px;
  }

  .rmb-100,
  .rmy-100 {
    margin-bottom: 100px;
  }

  .rmb-105,
  .rmy-105 {
    margin-bottom: 105px;
  }

  .rmb-110,
  .rmy-110 {
    margin-bottom: 110px;
  }

  .rmb-115,
  .rmy-115 {
    margin-bottom: 115px;
  }

  .rmb-120,
  .rmy-120 {
    margin-bottom: 120px;
  }

  .rmb-125,
  .rmy-125 {
    margin-bottom: 125px;
  }

  .rmb-130,
  .rmy-130 {
    margin-bottom: 130px;
  }

  .rmb-135,
  .rmy-135 {
    margin-bottom: 135px;
  }

  .rmb-140,
  .rmy-140 {
    margin-bottom: 140px;
  }

  .rmb-145,
  .rmy-145 {
    margin-bottom: 145px;
  }

  .rmb-150,
  .rmy-150 {
    margin-bottom: 150px;
  }
}

/*******************************************************/
/***************** 05. Custom Animation ****************/
/*******************************************************/
/* Animation Delay */
.delay-1-0s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.delay-2-0s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.delay-0-1s {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.delay-0-2s {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.delay-0-3s {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.delay-0-4s {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.delay-0-5s {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.delay-0-6s {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.delay-0-7s {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.delay-0-8s {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.delay-0-9s {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.delay-1-1s {
  -webkit-animation-delay: 1.1s;
  animation-delay: 1.1s;
}

.delay-1-2s {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.delay-1-3s {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

.delay-1-4s {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.delay-1-5s {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.delay-1-6s {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

.delay-1-7s {
  -webkit-animation-delay: 1.7s;
  animation-delay: 1.7s;
}

.delay-1-8s {
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}

.delay-1-9s {
  -webkit-animation-delay: 1.9s;
  animation-delay: 1.9s;
}

/* Menu Sticky */
@-webkit-keyframes sticky {
  0% {
    top: -100px;
  }

  100% {
    top: 0;
  }
}

@keyframes sticky {
  0% {
    top: -100px;
  }

  100% {
    top: 0;
  }
}

/* Hero Circle */
@-webkit-keyframes rotated_circle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotated_circle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotated_circle_two {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes rotated_circle_two {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@-webkit-keyframes border-radius {

  0%,
  100% {
    border-radius: 55% 45% 50% 50%;
  }

  50% {
    border-radius: 45% 55% 40% 40%;
  }
}

@keyframes border-radius {

  0%,
  100% {
    border-radius: 55% 45% 50% 50%;
  }

  50% {
    border-radius: 45% 55% 40% 40%;
  }
}

@-webkit-keyframes slideUpDown {

  0%,
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }
}

@keyframes slideUpDown {

  0%,
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }
}

@-webkit-keyframes moveLeftRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes moveLeftRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes shapeAnimationOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(0px, 150px) rotate(90deg);
    transform: translate(0px, 150px) rotate(90deg);
  }

  50% {
    -webkit-transform: translate(150px, 150px) rotate(180deg);
    transform: translate(150px, 150px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(150px, 0px) rotate(270deg);
    transform: translate(150px, 0px) rotate(270deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@keyframes shapeAnimationOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(0px, 150px) rotate(90deg);
    transform: translate(0px, 150px) rotate(90deg);
  }

  50% {
    -webkit-transform: translate(150px, 150px) rotate(180deg);
    transform: translate(150px, 150px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(150px, 0px) rotate(270deg);
    transform: translate(150px, 0px) rotate(270deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@-webkit-keyframes shapeAnimationTwo {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(-150px, 0px) rotate(270deg);
    transform: translate(-150px, 0px) rotate(270deg);
  }

  50% {
    -webkit-transform: translate(-150px, -150px) rotate(180deg);
    transform: translate(-150px, -150px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(0px, -150px) rotate(90deg);
    transform: translate(0px, -150px) rotate(90deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@keyframes shapeAnimationTwo {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(-150px, 0px) rotate(270deg);
    transform: translate(-150px, 0px) rotate(270deg);
  }

  50% {
    -webkit-transform: translate(-150px, -150px) rotate(180deg);
    transform: translate(-150px, -150px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(0px, -150px) rotate(90deg);
    transform: translate(0px, -150px) rotate(90deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@-webkit-keyframes shapeAnimationThree {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(50px, 150px) rotate(90deg);
    transform: translate(50px, 150px) rotate(90deg);
  }

  50% {
    -webkit-transform: translate(150px, 150px) rotate(180deg);
    transform: translate(150px, 150px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(150px, 50px) rotate(270deg);
    transform: translate(150px, 50px) rotate(270deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@keyframes shapeAnimationThree {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(50px, 150px) rotate(90deg);
    transform: translate(50px, 150px) rotate(90deg);
  }

  50% {
    -webkit-transform: translate(150px, 150px) rotate(180deg);
    transform: translate(150px, 150px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(150px, 50px) rotate(270deg);
    transform: translate(150px, 50px) rotate(270deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@-webkit-keyframes shapeAnimationFour {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(-150px -50px) rotate(90deg);
    transform: translate(-150px -50px) rotate(90deg);
  }

  50% {
    -webkit-transform: translate(-150px, -150px) rotate(180deg);
    transform: translate(-150px, -150px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(-50px, -150px) rotate(270deg);
    transform: translate(-50px, -150px) rotate(270deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@keyframes shapeAnimationFour {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(-150px -50px) rotate(90deg);
    transform: translate(-150px -50px) rotate(90deg);
  }

  50% {
    -webkit-transform: translate(-150px, -150px) rotate(180deg);
    transform: translate(-150px, -150px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(-50px, -150px) rotate(270deg);
    transform: translate(-50px, -150px) rotate(270deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@-webkit-keyframes shapeAnimationFive {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(-100px -100px) rotate(90deg);
    transform: translate(-100px -100px) rotate(90deg);
  }

  50% {
    -webkit-transform: translate(100px, 50px) rotate(180deg);
    transform: translate(100px, 50px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(200px, -150px) rotate(270deg);
    transform: translate(200px, -150px) rotate(270deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@keyframes shapeAnimationFive {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }

  25% {
    -webkit-transform: translate(-100px -100px) rotate(90deg);
    transform: translate(-100px -100px) rotate(90deg);
  }

  50% {
    -webkit-transform: translate(100px, 50px) rotate(180deg);
    transform: translate(100px, 50px) rotate(180deg);
  }

  75% {
    -webkit-transform: translate(200px, -150px) rotate(270deg);
    transform: translate(200px, -150px) rotate(270deg);
  }

  100% {
    -webkit-transform: translate(0px, 0px) rotate(360deg);
    transform: translate(0px, 0px) rotate(360deg);
  }
}

@-webkit-keyframes down-up-one {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }

  50% {
    -webkit-transform: rotateX(0deg) translateY(25px);
    transform: rotateX(0deg) translateY(25px);
  }

  100% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
}

@keyframes down-up-one {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }

  50% {
    -webkit-transform: rotateX(0deg) translateY(25px);
    transform: rotateX(0deg) translateY(25px);
  }

  100% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
}

@-webkit-keyframes down-up-two {
  0% {
    -webkit-transform: rotateX(0deg) translate(0px);
    transform: rotateX(0deg) translate(0px);
  }

  50% {
    -webkit-transform: rotateX(0deg) translate(25px, -25px);
    transform: rotateX(0deg) translate(25px, -25px);
  }

  100% {
    -webkit-transform: rotateX(0deg) translate(0px);
    transform: rotateX(0deg) translate(0px);
  }
}

@keyframes down-up-two {
  0% {
    -webkit-transform: rotateX(0deg) translate(0px);
    transform: rotateX(0deg) translate(0px);
  }

  50% {
    -webkit-transform: rotateX(0deg) translate(25px, -25px);
    transform: rotateX(0deg) translate(25px, -25px);
  }

  100% {
    -webkit-transform: rotateX(0deg) translate(0px);
    transform: rotateX(0deg) translate(0px);
  }
}

@-webkit-keyframes zoomInOut {
  0% {
    -webkit-transform: scale(0.35);
    transform: scale(0.35);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.35);
    transform: scale(0.35);
  }
}

@keyframes zoomInOut {
  0% {
    -webkit-transform: scale(0.35);
    transform: scale(0.35);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.35);
    transform: scale(0.35);
  }
}

@-webkit-keyframes pulse {
  to {
    -webkit-box-shadow: 0 0 0 35px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 35px rgba(255, 255, 255, 0);
  }
}

@keyframes pulse {
  to {
    -webkit-box-shadow: 0 0 0 35px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 35px rgba(255, 255, 255, 0);
  }
}

/*******************************************************/
/******************  06. Main Header *******************/
/*******************************************************/
.navbar-top {
  font-size: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
}

@media only screen and (max-width: 479px) {
  .navbar-top .topbar-left {
    display: none;
  }
}

.navbar-top .topbar-left .off {
  line-height: 1;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 12px;
  margin-right: 10px;
  border-radius: 5px;
  display: inline-block;
}

.navbar-top .topbar-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-top .topbar-right li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (max-width: 479px) {
  .navbar-top .topbar-right li:not(:last-child) {
    display: none;
  }
}

.navbar-top .topbar-right li:not(:last-child):after {
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #C6C3CC;
  margin-left: 30px;
  margin-right: 30px;
}

.navbar-top .topbar-right li>i {
  margin-right: 5px;
}

.navbar-top .topbar-right li b {
  font-weight: 600;
}

.navbar-top.text-white {
  z-index: 1;
  position: relative;
}

.navbar-top.text-white::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.95;
  background: -webkit-gradient(linear, left top, right top, from(#ff7e5f), to(#feb47b));
  background: -webkit-linear-gradient(left, #ff7e5f, #feb47b);
  background: -o-linear-gradient(left, #ff7e5f, #feb47b);
  background: linear-gradient(90deg, #0d6efd, #0dcaf0);
}

.navbar-top.text-white .topbar-left .off {
  background: white;
  color: #0d6efd;
}

.navbar-top.text-white .topbar-right li:not(:last-child):after {
  background: white;
}

.navbar-top.text-white .topbar-right li a,
.navbar-top.text-white .topbar-right li a i {
  color: white;
}

.navbar-area {
  position: absolute;
  width: 100%;
  z-index: 99;
  background-color: #000000;
}

.navbar-area .logo {
  max-width: 170px;
}

@media only screen and (max-width: 1199px) {
  .navbar-area .logo {
    max-width: 130px;
  }
}

.navbar-area .logo a {
  font-weight: 700;
  font-size: 24px;
  color: #fff;
}

.navbar-area .btn-transparent {
  font-size: 13px;
  font-weight: 700;
}

.navbar-area .navbar-collapse .navbar-nav {
  width: 100%;
}

.navbar-area .navbar-collapse .navbar-nav li {
  display: inline-block;
  font-weight: 500;
  line-height: 60px;
  text-transform: capitalize;
}

.navbar-area .navbar-collapse .navbar-nav li a {
  color: #ffffff;
}

.navbar-area .navbar-collapse .navbar-nav li:hover>a {
  color: #0d6efd;
}

.navbar-area .navbar-collapse .navbar-nav li+li {
  margin-left: 17px;
}

.navbar-area .navbar-collapse .navbar-nav li.menu-item-has-children {
  z-index: 1;
  position: relative;
}

.navbar-area .navbar-collapse .navbar-nav li.menu-item-has-children>a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 991px) {
  .navbar-area .navbar-collapse .navbar-nav li.menu-item-has-children>a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.navbar-area .navbar-collapse .navbar-nav li.menu-item-has-children>a:after {
  font-size: 15px;
  content: "\f107";
  margin-left: 5px;
  margin-top: 3px;
  color: #ffffff;
  font-family: "Font Awesome 5 Pro";
}

.navbar-area .navbar-collapse .navbar-nav li.menu-item-has-children:hover>.sub-menu {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.navbar-area .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu {
  position: absolute;
  text-align: left;
  min-width: 210px;
  margin: 0;
  padding: 0;
  list-style: none;
  left: 0;
  top: 100%;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  background-color: rgb(0, 0, 0);
  z-index: 9;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.35s;
  -o-transition: 0.35s;
  transition: 0.35s;
  border-radius: 0;
}

@media only screen and (min-width: 992px) {
  .navbar-area {
    background-color: #000000 !important;
  }

  .navbar-area .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu {
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    transform: translateY(10px);
  }
}

.navbar-area .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu.border-bt0 {
  border-bottom: 0px !important;
}

.navbar-area .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li {
  display: block;
  margin-left: 0;
  line-height: 22px;
  font-size: 15px;
  border-top: 1px solid #000000;
}

.navbar-area .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 15px;
  white-space: nowrap;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  font-size: 15px;
  font-weight: 500;
  position: relative;
}

.navbar-area .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li:hover {
  background: #0d6efd;
}

@media only screen and (min-width: 992px) {
  .navbar-area .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li:hover>a {
    color: #fff;
    padding-left: 20px;
  }

  .navbar-area .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li:hover>a:after {
    color: white;
  }
}

.navbar-area .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children {
  position: relative;
  z-index: 0;
  padding-right: 0px;
}

.navbar-area .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children:before {
  position: absolute;
  top: 20px;
  content: '';
}

.navbar-area .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children>.sub-menu {
  left: 100%;
  top: 0;
}

.navbar-area .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children>.sub-menu .sub-menu .sub-menu {
  left: auto;
  right: 100%;
}

.navbar-area .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children:hover>.sub-menu {
  visibility: visible;
  opacity: 1;
}

.navbar-area .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children:hover>.sub-menu li:hover:before {
  color: white;
}

.navbar-area .navbar-collapse .navbar-nav>li {
  margin-right: 15px;
}

.navbar-area.rel {
  position: relative;
}

.navbar-nav {
  opacity: 0;
  margin-right: -30px;
  visibility: hidden;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.sticky-active {
  -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  -webkit-box-shadow: 0 10px 20px 0 rgba(46, 56, 220, 0.05);
  box-shadow: 0 10px 20px 0 rgba(46, 56, 220, 0.05);
  border-bottom: 0;
  background: #000000;
  padding-top: 10px;
  padding-bottom: 10px;
}

.sticky-active.rel {
  position: fixed;
}

.menu-open {
  opacity: 1;
  margin-right: 0;
  visibility: visible;
}

.responsive-mobile-menu button:focus {
  outline: none;
  border: none;
}

/**************** nav-right-part *************/
.nav-right-part {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-right-part>button {
  font-size: 20px;
  margin-left: 12px;
  margin-right: 12px;
  color: #ffffff;
  background: transparent;
}

.nav-right-part .theme-btn {
  margin-left: 13px;
}

@media only screen and (max-width: 1199px) {
  .nav-right-part .theme-btn {
    display: none;
  }
}

.nav-right-part .search-bar:hover {
  background: #0d6efd;
  border: 1px solid #0d6efd;
}

.nav-right-part-mobile {
  display: none;
}

/* Menu Sidebar */
.menu-sidebar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.menu-sidebar button {
  cursor: pointer;
  font-size: 28px;
  margin-left: 30px;
  color: #0d6efd;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: transparent;
}

.menu-sidebar button i {
  margin: -9px 0;
}

/** hidden-sidebar * */
.hidden-bar {
  position: fixed;
  right: -350px;
  top: 0px;
  opacity: 0;
  width: 350px;
  height: 100%;
  z-index: 99999;
  overflow-y: auto;
  visibility: hidden;
  background-color: #000000;
  border-left: 1px solid #231b26;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

@media only screen and (max-width: 375px) {
  .hidden-bar {
    width: 300px;
  }
}

.side-content-visible .hidden-bar {
  right: 0px;
  opacity: 1;
  visibility: visible;
}

.hidden-bar .inner-box {
  position: relative;
  padding: 100px 40px 50px;
}

@media only screen and (max-width: 375px) {
  .hidden-bar .inner-box {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.hidden-bar .inner-box .cross-icon {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  color: #ffffff;
  font-size: 20px;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

/*Appointment Form */
.hidden-bar .appointment-form {
  position: relative;
}

.hidden-bar .appointment-form .form-group {
  position: relative;
  margin-bottom: 20px;
}

.hidden-bar .appointment-form input[type="text"],
.hidden-bar .appointment-form input[type="email"] {
  position: relative;
  display: block;
  width: 100%;
  line-height: 23px;
  padding: 10px 25px;
  color: #ffffff;
  font-size: 16px;
  background: none;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.hidden-bar .appointment-form textarea {
  position: relative;
  display: block;
  width: 100%;
  line-height: 23px;
  padding: 10px 25px;
  color: #ffffff;
  font-size: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: none;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  resize: none;
}

.hidden-bar .appointment-form input::-webkit-input-placeholder {
  font-size: 14px;
  color: #bdbdbd;
}

.hidden-bar .appointment-form input:-ms-input-placeholder {
  font-size: 14px;
  color: #bdbdbd;
}

.hidden-bar .appointment-form input::-ms-input-placeholder {
  font-size: 14px;
  color: #bdbdbd;
}

.hidden-bar .appointment-form input::placeholder {
  font-size: 14px;
  color: #bdbdbd;
}

.hidden-bar .appointment-form textarea::-webkit-input-placeholder {
  font-size: 14px;
  color: #bdbdbd;
}

.hidden-bar .appointment-form textarea:-ms-input-placeholder {
  font-size: 14px;
  color: #bdbdbd;
}

.hidden-bar .appointment-form textarea::-ms-input-placeholder {
  font-size: 14px;
  color: #bdbdbd;
}

.hidden-bar .appointment-form textarea::placeholder {
  font-size: 14px;
  color: #bdbdbd;
}

.hidden-bar .appointment-form .form-group button {
  width: 100%;
  font-size: 16px;
  padding: 10px 15px;
  margin-bottom: 20px;
  border-color: white;
}

.form-back-drop {
  position: fixed;
  right: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(0, 0, 0, 0.7);
  visibility: hidden;
  z-index: 9990;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.side-content-visible .form-back-drop {
  opacity: 1;
  visibility: visible;
}

/* Header One */
.navbar.style-one .nav-right-part-desktop {
  margin-left: auto;
}

.navbar.style-one .navbar-collapse {
  -webkit-box-flex: unset;
  -ms-flex-positive: unset;
  flex-grow: unset;
  margin-left: auto;
  margin-right: auto;
}

.navbar.style-one .navbar-collapse .navbar-nav {
  display: block;
}

/* Header Two */
.navbar-top.style-two .topbar-left {
  color: black;
}

.navbar-top.style-two .topbar-left .off {
  color: white;
  background: -webkit-gradient(linear, left top, right top, from(#ff7e5f), to(#feb47b));
  background: -webkit-linear-gradient(left, #ff7e5f, #feb47b);
  background: -o-linear-gradient(left, #ff7e5f, #feb47b);
  background: linear-gradient(90deg, #0d6efd, #0dcaf0);
}

.navbar-top.style-two .topbar-right li>i {
  color: #0d6efd;
}

.navbar.style-two {
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 991px) {
  .navbar.style-two {
    padding: 15px;
  }
}

@media only screen and (min-width: 992px) {
  .navbar.style-two>.logo {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .navbar.style-two .navbar-collapse .navbar-nav {
    margin-bottom: 0;
  }
}

.navbar.style-two .navbar-collapse .navbar-nav>li {
  margin: 0;
  padding: 8px 70px;
  position: relative;
}

@media only screen and (min-width: 992px) {
  .navbar.style-two .navbar-collapse .navbar-nav>li {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (max-width: 1599px) {
  .navbar.style-two .navbar-collapse .navbar-nav>li {
    padding-left: 45px;
    padding-right: 45px;
  }
}

@media only screen and (max-width: 1199px) {
  .navbar.style-two .navbar-collapse .navbar-nav>li {
    padding: 0 25px;
  }
}

@media only screen and (max-width: 991px) {
  .navbar.style-two .navbar-collapse .navbar-nav>li {
    padding: 2px 0;
  }
}

.navbar.style-two .navbar-collapse .navbar-nav>li>.sub-menu {
  left: auto;
}

.navbar.style-two .navbar-collapse .navbar-nav>li>a:before {
  width: 70%;
  height: 70%;
  content: '';
  left: 15%;
  top: 15%;
  opacity: 0;
  z-index: -1;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  visibility: hidden;
  position: absolute;
  -webkit-transform: scale(0) rotate(-33deg);
  -ms-transform: scale(0) rotate(-33deg);
  transform: scale(0) rotate(-33deg);
  background: url(../images/shapes/menu-bg.png) no-repeat 100%/100%;
}

@media only screen and (max-width: 991px) {
  .navbar.style-two .navbar-collapse .navbar-nav>li>a:before {
    display: none;
  }
}

.navbar.style-two .navbar-collapse .navbar-nav>li>a:hover:before {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1) rotate(0deg);
  -ms-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
}

.navbar.style-two .navbar-collapse .navbar-nav .logo {
  max-width: none;
  margin-left: auto;
  margin-right: auto;
  border-right: none;
}

@media only screen and (max-width: 991px) {
  .navbar.style-two .navbar-collapse .navbar-nav .logo {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .navbar.style-two .navbar-collapse .navbar-nav .logo+li {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.navbar.style-two .menu-sidebar button {
  margin-left: 70px;
  margin-right: 70px;
}

@media only screen and (max-width: 991px) {
  .navbar-area {
    background: #000000;
  }

  .nav-right-part {
    margin-right: 60px;
  }

  .nav-right-part .btn .right {
    padding-left: 5px;
    font-size: 13px;
  }

  .navbar-area .navbar-collapse .navbar-nav>li {
    margin-right: 0;
    padding-right: 0;
  }

  .navbar-area .navbar-toggler {
    padding: 0px;
  }

  .navbar-area .navbar-collapse {
    margin-top: 13px;
  }

  .navbar-area .navbar-collapse .navbar-nav {
    display: block;
    margin-top: 20px;
  }

  .navbar-area .navbar-collapse .navbar-nav li {
    display: block;
    text-align: left;
    line-height: 30px;
    padding: 6px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .navbar-area .navbar-collapse .navbar-nav li a {
    display: block;
  }

  .navbar-area .navbar-collapse .navbar-nav li:last-child {
    border-bottom: none;
  }

  .navbar-area .navbar-collapse .navbar-nav li+li {
    margin-left: 0;
  }

  .navbar-area .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu {
    position: initial;
    display: block;
    width: 100%;
    border-top: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-left: 0;
    padding-bottom: 0;
    height: auto;
    max-height: 650px;
    background-color: transparent;
    border-radius: 10px;
    padding: 0px;
    border-bottom: none;
    display: none;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
    visibility: visible;
    opacity: 1;
  }

  .navbar-area .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .sub-menu .menu-item-has-children:before {
    content: "\f107";
  }

  .navbar-area .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li {
    padding: 0;
  }

  .navbar-area .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li+li {
    border-top: none;
  }
}

.sopen {
  -webkit-animation: 0.5s fadeIn;
  animation: 0.5s fadeIn;
  display: block !important;
}

.toggle-btn {
  left: auto;
  right: 0;
  position: absolute;
  width: 30px;
  height: 30px;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  border: 0;
  background: transparent;
}

.toggle-btn .icon-left {
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  position: absolute;
  height: 2px;
  width: 11px;
  top: 18px;
  background-color: #ffffff;
  left: 7px;
}

.toggle-btn .icon-left:before {
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  position: absolute;
  width: 11px;
  height: 2px;
  background-color: #ffffff;
  content: "";
  top: -7px;
  left: 0;
}

.toggle-btn .icon-left:after {
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  position: absolute;
  width: 11px;
  height: 2px;
  background-color: #ffffff;
  content: "";
  top: 7px;
  left: 0;
}

.toggle-btn .icon-left:hover {
  cursor: pointer;
}

.toggle-btn .icon-right {
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  position: absolute;
  height: 2px;
  width: 11px;
  top: 18px;
  background-color: #ffffff;
  left: 18px;
}

.toggle-btn .icon-right:before {
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  position: absolute;
  width: 11px;
  height: 2px;
  background-color: #ffffff;
  content: "";
  top: -7px;
  left: 0;
}

.toggle-btn .icon-right:after {
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  position: absolute;
  width: 11px;
  height: 2px;
  background-color: #ffffff;
  content: "";
  top: 7px;
  left: 0;
}

.toggle-btn .icon-right:hover {
  cursor: pointer;
}

.toggle-btn.open .icon-left {
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  background: transparent;
}

.toggle-btn.open .icon-left:before {
  -webkit-transform: rotateZ(45deg) scaleX(1.4) translate(2px, 1px);
  -ms-transform: rotate(45deg) scaleX(1.4) translate(2px, 1px);
  transform: rotateZ(45deg) scaleX(1.4) translate(2px, 1px);
}

.toggle-btn.open .icon-left:after {
  -webkit-transform: rotateZ(-45deg) scaleX(1.4) translate(2px, -1px);
  -ms-transform: rotate(-45deg) scaleX(1.4) translate(2px, -1px);
  transform: rotateZ(-45deg) scaleX(1.4) translate(2px, -1px);
}

.toggle-btn.open .icon-right {
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  background: transparent;
}

.toggle-btn.open .icon-right:before {
  -webkit-transform: rotateZ(-45deg) scaleX(1.4) translate(-2px, 1px);
  -ms-transform: rotate(-45deg) scaleX(1.4) translate(-2px, 1px);
  transform: rotateZ(-45deg) scaleX(1.4) translate(-2px, 1px);
}

.toggle-btn.open .icon-right:after {
  -webkit-transform: rotateZ(45deg) scaleX(1.4) translate(-2px, -1px);
  -ms-transform: rotate(45deg) scaleX(1.4) translate(-2px, -1px);
  transform: rotateZ(45deg) scaleX(1.4) translate(-2px, -1px);
}

.toggle-btn:hover {
  cursor: pointer;
}

/* Header Three */
.header-wrapper {
  position: absolute;
  width: 100%;
  z-index: 3;
}

.header-wrapper .navbar-top {
  padding-top: 20px;
  padding-bottom: 20px;
}

@media only screen and (max-width: 375px) {
  .header-wrapper .navbar-top {
    display: none;
  }
}

.header-wrapper .topbar-left {
  font-weight: 500;
  color: #000000;
}

.header-wrapper .topbar-left .off {
  color: white;
  background: #0d6efd;
}

.header-wrapper .topbar-right .nice-select {
  background: transparent;
  position: relative;
  z-index: 100;
  width: auto;
  font-size: 14px;
  line-height: 1.7;
  margin-right: 15px;
  text-transform: uppercase;
  padding: 6px 28px 6px 15px;
}

.header-wrapper .topbar-right .nice-select .current {
  color: #7e7e7e;
}

.header-wrapper .topbar-right .nice-select ul li:after {
  display: none;
}

.header-wrapper .topbar-right .nice-select:after {
  width: 7px;
  height: 7px;
  top: 45%;
  right: 15px;
}

.header-wrapper .topbar-right .follower {
  font-weight: 500;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.header-wrapper .topbar-right .follower i {
  font-size: 18px;
  margin-right: 7px;
  color: #0d6efd;
}

@media only screen and (max-width: 479px) {
  .header-wrapper .topbar-right .follower a {
    font-size: 14px;
  }
}

.header-wrapper .navbar {
  padding-top: 0;
  padding-bottom: 0;
}

.header-wrapper .navbar .container {
  z-index: 1;
  position: relative;
  padding-left: 55px;
  padding-right: 55px;
}

@media only screen and (max-width: 575px) {
  .header-wrapper .navbar .container {
    padding-left: 35px;
    padding-right: 35px;
  }
}

.header-wrapper .navbar .container:after {
  content: '';
  z-index: -1;
  top: 0;
  left: 15px;
  background: white;
  position: absolute;
  width: calc(100% - 30px);
  height: 100%;
}

.header-wrapper .navbar.sticky-active {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
}

.header-wrapper .navbar.sticky-active .container:after {
  -webkit-box-shadow: 0 10px 20px 0 rgba(46, 56, 220, 0.05);
  box-shadow: 0 10px 20px 0 rgba(46, 56, 220, 0.05);
}

@media only screen and (max-width: 575px) {
  .header-wrapper .nav-right-part {
    margin-right: 45px;
  }
}

@media only screen and (min-width: 992px) {
  .header-wrapper .mobile-logo {
    display: none;
  }
}

@media only screen and (max-width: 375px) {
  .header-wrapper .mobile-logo {
    max-width: 135px;
  }
}

@media only screen and (max-width: 991px) {
  .header-wrapper .logo {
    display: none;
  }
}

.header-wrapper .menu-sidebar button {
  margin-left: 12px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-area .navbar-collapse .navbar-nav li {
    font-size: 16px;
  }

  .navbar-area .navbar-collapse .navbar-nav li+li {
    margin-left: 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .responsive-mobile-menu {
    display: block;
    width: 100%;
    position: relative;
  }
}

@media only screen and (max-width: 1199px) {
  .margin-xlt-80 {
    margin-top: 0px;
  }

  .contact-widget .contact_info_list li.single-info-item .details {
    padding-left: 25px;
  }
}

@media only screen and (max-width: 991px) {
  .navbar-collapse {
    margin-top: 0px;
    width: 100%;
  }

  .navbar-area .navbar-collapse .navbar-nav {
    margin-bottom: 20px;
  }

  .party-box-wrapper {
    padding: 50px 0px;
  }

  .party-box-wrapper .party-box-content h4 {
    font-size: 30px;
    line-height: 45px;
  }

  .navbar-expand-lg .navbar-collapse {
    margin-top: 0px;
  }

  .contact-widget .contact_info_list li.single-info-item .details {
    padding-left: 25px;
  }

  .navbar-area .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu {
    padding: 0 0 0 20px;
  }

  .navbar-area .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a {
    padding: 8px 0;
  }

  .navbar-area .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a:hover i {
    opacity: 0;
    margin-right: -18px;
  }

  .nav-right-part-mobile {
    display: block;
    margin-bottom: -6px;
  }

  .nav-right-part-desktop {
    display: none;
  }

  .navbar-area .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li:hover {
    background: none;
    color: #0d6efd;
  }

  .navbar-area .navbar-collapse .navbar-nav li a {
    color: #ffffff;
  }
}

/* Tablet Layout wide: 767px. */
@media only screen and (max-width: 767px) {
  .logo-wrapper.mobile-logo {
    display: block;
    width: 100%;
  }

  .responsive-mobile-menu {
    display: block;
    width: 100%;
    position: relative;
  }

  .responsive-mobile-menu .navbar-toggler {
    position: absolute;
    left: calc(100% - 130px);
    top: 10px;
  }

  .table-responsive {
    display: block !important;
  }

  .btn-custom-default,
  .btn-custom-white {
    font-size: 14PX;
    line-height: 33px;
    padding: 6px 20px;
  }

  .navbar-area .logo {
    padding-top: 0px !important;
  }
}

/* medium tablet layout 599px */
@media only screen and (max-width: 575px) {
  .navbar-area .logo {
    padding-top: 10px;
  }

  .widget.footer-widget .subscribe-form.subscribe-form-style2 .form-control {
    padding: 15px 20px;
  }

  .widget.footer-widget .subscribe-form.subscribe-form-style2 .btn {
    padding: 15px 20px;
  }

  .search-popup .search-form {
    min-width: 350px;
  }
}

@media only screen and (max-width: 375px) {

  .btn-custom-default,
  .btn-custom-white {
    padding: 5px 18px;
  }

  .search-popup .search-form .form-group .form-control,
  .search-popup .search-form .submit-btn {
    height: 45px;
  }

  .search-popup .search-form {
    min-width: 300px;
  }
}

@media only screen and (max-width: 320px) {
  .sm-mt-pp {
    margin-top: 60px;
  }

  .search-popup .search-form {
    min-width: 265px;
  }

  .responsive-mobile-menu .navbar-toggler {
    left: calc(100% - 95px);
  }
}

/*******************************************************/
/******************  07. PopUp Search ******************/
/*******************************************************/
.td-search-popup.active .search-form {
  visibility: visible;
  opacity: 1;
  width: 40%;
  z-index: 9999;
}

@media only screen and (max-width: 991px) {
  .td-search-popup.active .search-form {
    width: 75%;
  }
}

.td-search-popup .search-form {
  width: 35%;
  position: absolute;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 999;
}

.td-search-popup .search-form .form-group .form-control {
  border: none;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 54px;
  padding: 0 75px 0 25px;
  background: #f7f9fb;
}

.td-search-popup .search-form .form-group .form-control:hover,
.td-search-popup .search-form .form-group .form-control:focus,
.td-search-popup .search-form .form-group .form-control:active {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.td-search-popup .search-form .submit-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 54px;
  border: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #ffffff;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  padding: 0;
  text-align: center;
  cursor: pointer;
  background: #0d6efd;
}

.td-search-popup .search-form .submit-btn:hover,
.td-search-popup .search-form .submit-btn:focus,
.td-search-popup .search-form .submit-btn:active {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.body-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(0, 0, 0, 0.95);
  z-index: 999;
  content: "";
  left: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVBAMAAABbObilAAAAMFBMVEVMaXH////////////////////////////////////////////////////////////6w4mEAAAAD3RSTlMAlAX+BKLcA5+b6hJ7foD4ZP1OAAAAkUlEQVR4XkWPoQ3CUBQAL4SktoKAbCUjgAKLJZ2ABYosngTJCHSD6joUI6BZgqSoB/+Shqde7sS9x3OGk81fdO+texMtRVTia+TsQtHEUJLdohJfgNNPJHyEJPZTsWLoxShqsWITazEwqePAn69Sw2TUxk1+euPis3EwaXy8RMHSZBIlRcKKnC5hRctjMf57/wJbBlAIs9k1BAAAAABJRU5ErkJggg==), progress;
}

.body-overlay.active {
  visibility: visible;
  opacity: 0.97;
}

.mfp-zoom-in .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
}

.mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.mfp-zoom-in.mfp-ready .mfp-content {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.7;
}

.mfp-zoom-in.mfp-ready.mfp-bg .mfp-zoom-in.mfp-removing .mfp-content {
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  opacity: 0;
}

.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

/************ single-inpur-inner ************/
.single-input-inner {
  margin-bottom: 20px;
}

.single-input-inner input {
  width: 100%;
  height: 48px;
  border: 0 !important;
  padding: 0 18px;
  font-weight: 300;
}

.single-input-inner input::-webkit-input-placeholder {
  color: #adadad;
}

.single-input-inner input:-ms-input-placeholder {
  color: #adadad;
}

.single-input-inner input::-ms-input-placeholder {
  color: #adadad;
}

.single-input-inner input::placeholder {
  color: #adadad;
}

.single-input-inner textarea {
  width: 100%;
  border: 0 !important;
  height: 130px;
  border-radius: 6px;
  padding: 14px 18px;
  font-weight: 300;
}

.single-input-inner textarea::-webkit-input-placeholder {
  color: #adadad;
}

.single-input-inner textarea:-ms-input-placeholder {
  color: #adadad;
}

.single-input-inner textarea::-ms-input-placeholder {
  color: #adadad;
}

.single-input-inner textarea::placeholder {
  color: #adadad;
}

.single-input-inner .single-select {
  width: 100%;
  height: 48px;
  line-height: 48px;
  border: 0 !important;
  border-radius: 30px;
  padding: 0 18px;
  margin-bottom: 20px;
}

.single-input-inner .single-select .list {
  width: 100%;
}

.single-input-inner.style-border input {
  border: 1px solid rgba(234, 225, 214, 0.7) !important;
}

.single-input-inner.style-border input::-webkit-input-placeholder {
  color: #adadad;
}

.single-input-inner.style-border input:-ms-input-placeholder {
  color: #adadad;
}

.single-input-inner.style-border input::-ms-input-placeholder {
  color: #adadad;
}

.single-input-inner.style-border input::placeholder {
  color: #adadad;
}

.single-input-inner.style-border textarea {
  border: 1px solid rgba(234, 225, 214, 0.7) !important;
  background: transparent;
}

.single-input-inner.style-border textarea::-webkit-input-placeholder {
  color: #adadad;
}

.single-input-inner.style-border textarea:-ms-input-placeholder {
  color: #adadad;
}

.single-input-inner.style-border textarea::-ms-input-placeholder {
  color: #adadad;
}

.single-input-inner.style-border textarea::placeholder {
  color: #adadad;
}

.single-input-inner.style-border .single-select {
  border: 1px solid rgba(234, 225, 214, 0.7) !important;
  color: #adadad;
}

.single-input-inner.style-border-bottom input {
  border-bottom: 1px solid rgba(234, 225, 214, 0.7) !important;
  background: transparent;
  padding: 0;
}

.single-input-inner.style-border-bottom input::-webkit-input-placeholder {
  color: #adadad;
}

.single-input-inner.style-border-bottom input:-ms-input-placeholder {
  color: #adadad;
}

.single-input-inner.style-border-bottom input::-ms-input-placeholder {
  color: #adadad;
}

.single-input-inner.style-border-bottom input::placeholder {
  color: #adadad;
}

.single-input-inner.style-border-bottom textarea {
  border-bottom: 1px solid rgba(234, 225, 214, 0.7) !important;
}

.single-input-inner.style-border-bottom textarea::-webkit-input-placeholder {
  color: #adadad;
}

.single-input-inner.style-border-bottom textarea:-ms-input-placeholder {
  color: #adadad;
}

.single-input-inner.style-border-bottom textarea::-ms-input-placeholder {
  color: #adadad;
}

.single-input-inner.style-border-bottom textarea::placeholder {
  color: #adadad;
}

.single-input-inner.style-border-bottom .single-select {
  border-bottom: 1px solid rgba(234, 225, 214, 0.7) !important;
  background: transparent;
  color: #adadad;
}

.single-input-inner.style-bg input {
  background: #F2F7FF;
  border-radius: 5px;
}

.single-input-inner.style-bg input::-webkit-input-placeholder {
  color: #616161;
}

.single-input-inner.style-bg input:-ms-input-placeholder {
  color: #616161;
}

.single-input-inner.style-bg input::-ms-input-placeholder {
  color: #616161;
}

.single-input-inner.style-bg input::placeholder {
  color: #616161;
}

.single-input-inner.style-bg textarea {
  background: #F2F7FF;
}

.single-input-inner.style-bg textarea::-webkit-input-placeholder {
  color: #616161;
  border-radius: 5px;
}

.single-input-inner.style-bg textarea:-ms-input-placeholder {
  color: #616161;
  border-radius: 5px;
}

.single-input-inner.style-bg textarea::-ms-input-placeholder {
  color: #616161;
  border-radius: 5px;
}

.single-input-inner.style-bg textarea::placeholder {
  color: #616161;
  border-radius: 5px;
}

.single-input-inner.style-bg .single-select {
  background: #F2F7FF;
  color: #616161;
  border-radius: 5px;
}

.single-input-inner.style-bg-none input {
  background: transparent;
  color: #fff;
}

.single-input-inner.style-bg-none input::-webkit-input-placeholder {
  color: #fff;
}

.single-input-inner.style-bg-none input:-ms-input-placeholder {
  color: #fff;
}

.single-input-inner.style-bg-none input::-ms-input-placeholder {
  color: #fff;
}

.single-input-inner.style-bg-none input::placeholder {
  color: #fff;
}

.single-input-inner.style-bg-none textarea {
  background: transparent;
  color: #fff;
}

.single-input-inner.style-bg-none textarea::-webkit-input-placeholder {
  color: #fff;
}

.single-input-inner.style-bg-none textarea:-ms-input-placeholder {
  color: #fff;
}

.single-input-inner.style-bg-none textarea::-ms-input-placeholder {
  color: #fff;
}

.single-input-inner.style-bg-none textarea::placeholder {
  color: #fff;
}

.single-input-inner.style-bg-none .single-select {
  background: transparent;
  color: #fff;
}

.contact-form-inner {
  padding: 0 30px 30px;
  border-radius: 7px;
  overflow: hidden;
  background-size: cover;
}

.contact-form-inner .single-input-inner.style-border-bottom input {
  border-bottom: 1px solid #fff !important;
}

.contact-form-inner .single-input-inner.style-border-bottom input::-webkit-input-placeholder {
  color: #fff;
}

.contact-form-inner .single-input-inner.style-border-bottom input:-ms-input-placeholder {
  color: #fff;
}

.contact-form-inner .single-input-inner.style-border-bottom input::-ms-input-placeholder {
  color: #fff;
}

.contact-form-inner .single-input-inner.style-border-bottom input::placeholder {
  color: #fff;
}

.blog-comment-form textarea {
  background: #FBFBFB;
  border: 1px solid #FBFBFB;
}

/*******************************************************/
/*******************  08. Hero Area ********************/
/*******************************************************/




.hero-content h1 {
  font-size: 65px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .hero-content h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .hero-content h1 {
    font-size: 45px;
    line-height: 1.4;
  }
}

@media only screen and (max-width: 767px) {
  .hero-content h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .hero-content h1 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 479px) {
  .hero-content h1 {
    font-size: 30px;
  }
}

.hero-content p {
  font-size: 20px;
}

@media only screen and (max-width: 479px) {
  .hero-content p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 375px) {
  .hero-content p {
    font-size: 15px;
  }
}

.hero-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: -10px;
  margin-right: -10px;
}

.hero-btns .theme-btn {
  margin: 15px 10px 0;
}

.hero-images {
  position: relative;
  z-index: 1;
  padding: 18% 20% 10%;
  margin-right: -20%;
}

@media only screen and (max-width: 1399px) {
  .hero-images {
    margin-right: -10%;
  }
}

@media only screen and (max-width: 991px) {
  .hero-images {
    margin-right: 0;
  }
}

.hero-images .image-one {
  position: absolute;
  left: -7%;
  top: -116%;
  width: 48.5%;
}

.hero-images .image-two {
  position: absolute;
  bottom: -7%;
  right: -6%;
  width: 41%;
}

.circle-shapes {
  position: absolute;
  width: 90%;
  height: 0;
  z-index: -1;
  top: 50%;
  left: 50%;
  padding-top: 90%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.circle-shapes:after,
.circle-shapes:before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.circle-shapes:before {
  left: 0;
  top: 3%;
  -webkit-animation: 50s moveLeftRight infinite;
  animation: 50s moveLeftRight infinite;
}

.circle-shapes:after {
  left: 10%;
  top: -3%;
  -webkit-animation: 50s slideUpDown infinite;
  animation: 50s slideUpDown infinite;
}

.circle-shapes .dot-one,
.circle-shapes .dot-two,
.circle-shapes .dot-three,
.circle-shapes .dot-four,
.circle-shapes .dot-five {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  position: absolute;
  -webkit-animation: 3s zoomInOut infinite;
  animation: 3s zoomInOut infinite;
}

.circle-shapes .dot-one {
  background: #1d46dd;
  left: 30%;
  top: -5%;
}

.circle-shapes .dot-two {
  background: #01d6e3;
  left: 102%;
  top: 15%;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.circle-shapes .dot-three {
  background: #feb47b;
  left: 91%;
  top: 37%;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.circle-shapes .dot-four {
  background: #0d6efd;
  left: 55%;
  bottom: -5%;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.circle-shapes .dot-five {
  background: #01d6e3;
  left: 0;
  bottom: 25%;
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

.circle-shapes .shape-inner {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  -webkit-animation: 50s rotated_circle infinite;
  animation: 50s rotated_circle infinite;
}

/* Hero Two */
.hero-left-image,
.hero-right-image {
  max-width: 100%;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
}

.hero-left-image .circle-shapes,
.hero-right-image .circle-shapes {
  width: 130%;
  padding-top: 130%;
}

.hero-left-image .circle-shapes:after,
.hero-right-image .circle-shapes:after {
  top: 0;
  left: 8%;
}

.hero-left-image .circle-shapes {
  width: 130%;
  padding-top: 130%;
}

.hero-right-image .circle-shapes {
  width: 120%;
  padding-top: 120%;
}

@media only screen and (max-width: 1199px) {
  .hero-two-content {
    margin-top: 70px;
    margin-bottom: 100px;
  }
}

.hero-two-content .sub-title {
  color: #ff7e5f;
}

.hero-two-content h1 {
  margin-bottom: 25px;
}

@media only screen and (max-width: 1599px) {
  .hero-two-content h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 1399px) {
  .hero-two-content h1 {
    font-size: 42px;
    line-height: 1.25;
  }
}

@media only screen and (max-width: 479px) {
  .hero-two-content h1 {
    font-size: 35px;
  }
}

@media only screen and (min-width: 376px) {
  .hero-two-content p {
    font-size: 20px;
  }
}

/* Hero Three */
.hero-three-area {
  overflow: hidden;
}

@media only screen and (max-width: 479px) {
  .hero-three-area {
    padding-top: 105px;
  }
}

@media only screen and (max-width: 375px) {
  .hero-three-area {
    padding-top: 25px;
  }
}

.hero-three-area .hero-left-image {
  z-index: 1;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .hero-three-area .hero-left-image {
    margin-top: 150px;
    margin-bottom: 60px;
  }
}

.hero-three-area .hero-left-image:after {
  content: '';
  top: -35px;
  left: 50%;
  width: 135%;
  z-index: -1;
  padding-top: 135%;
  border-radius: 50%;
  position: absolute;
  background: -webkit-gradient(linear, left top, right top, from(#ff7e5f), to(#feb47b));
  background: -webkit-linear-gradient(left, #ff7e5f, #feb47b);
  background: -o-linear-gradient(left, #ff7e5f, #feb47b);
  background: linear-gradient(90deg, #0d6efd, #0dcaf0);
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
}

@media only screen and (max-width: 575px) {
  .hero-three-area .hero-left-image:after {
    width: 115%;
    padding-top: 115%;
  }
}

@media only screen and (max-width: 1199px) {
  .hero-three-area .hero-three-content {
    margin-bottom: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-three-area .hero-three-content h1 {
    font-size: 50px;
    line-height: 1.25;
  }
}

@media only screen and (max-width: 991px) {
  .hero-three-area .hero-three-content h1 {
    font-size: 42px;
  }
}

@media only screen and (max-width: 479px) {
  .hero-three-area .hero-three-content h1 {
    font-size: 35px;
  }
}

.hero-three-area .hero-bg-circle {
  position: absolute;
  z-index: -1;
  left: 3%;
  top: 0;
  max-width: 25%;
  -webkit-animation: 50s rotated_circle infinite;
  animation: 50s rotated_circle infinite;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .hero-three-area .row>div {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.3333%;
  }
}



/*******************************************************/
/*******************  09. Banner Area ******************/
/*******************************************************/
.page-banner {
  z-index: 1;
  position: relative;
}

.page-banner::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 90%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 1;
}

@media only screen and (min-width: 1600px) {
  .page-banner {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.page-banner.style-two:after,
.page-banner.style-two:before {
  display: none;
}

@media only screen and (min-width: 1200px) {
  .page-banner.style-two .page-title {
    font-size: 65px;
  }
}

.banner-inner .page-title {
  margin-bottom: 20px;
  text-transform: capitalize;
}

@media only screen and (max-width: 767px) {
  .banner-inner .page-title {
    font-size: 35px;
  }
}

@media only screen and (max-width: 575px) {
  .banner-inner .page-title {
    font-size: 30px;
  }
}

.banner-inner .breadcrumb {
  margin-bottom: 0;
  padding: 4px 20px;
  border-radius: 5px;
  background: #0d6efd;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@media only screen and (max-width: 575px) {
  .banner-inner .breadcrumb {
    font-size: 16px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

.banner-inner .breadcrumb .breadcrumb-item {
  color: white;
  font-weight: 500;
  text-transform: capitalize;
}

.banner-inner .breadcrumb .breadcrumb-item a {
  color: white;
}

.banner-inner .breadcrumb .breadcrumb-item+.breadcrumb-item {
  padding-left: 10px;
}

.banner-inner .breadcrumb .breadcrumb-item+.breadcrumb-item:before {
  color: white;
  content: '\f105';
  padding-right: 10px;
  font-family: 'Font Awesome 5 Pro';
}

/*******************************************************/
/*******************  10. About Area *******************/
/*******************************************************/
.about-images {
  z-index: 1;
  position: relative;
}

.about-images .image {
  margin-bottom: 30px;
}

.about-images img {
  width: 100%;
}

.about-images .circle-shapes {
  width: 100%;
  padding-top: 100%;
  -webkit-transform: translate(-55%, -44%);
  -ms-transform: translate(-55%, -44%);
  transform: translate(-55%, -44%);
}

.about-video {
  z-index: 1;
  position: relative;
}

.about-video::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 97%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.2;
  background: black;
  border-radius: 25px;
}

.about-video:before {
  z-index: 1;
}

.about-video .video-play2 {
  z-index: 2;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.about-content {
  max-width: 500px;
}

@media only screen and (min-width: 992px) {
  .about-content {
    margin-left: auto;
  }
}

.experience-year {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
  position: relative;
}

@media only screen and (max-width: 479px) {
  .experience-year {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .about-page-video .video-play2 {
    width: 60px;
    height: 60px;
    font-size: 20px;
    line-height: 60px;
    border-radius: 50%;
    text-align: center;
  }
}

.experience-year:before {
  position: absolute;
  content: '';
  height: 1px;
  width: 70px;
  left: 0;
  top: 30px;
  background: black;
}

@media only screen and (max-width: 479px) {
  .experience-year:before {
    top: 15px;
  }
}

.experience-year h3 {
  font-size: 19px;
  margin: 10px 0;
}

.experience-year h3:first-child {
  font-size: 19px;
  padding-top: 20px;
}

.experience-year .number {
  line-height: 1;
  font-weight: 600;
  font-size: 120px;
  margin-left: 6%;
  margin-right: 6%;
  color: #0d6efd;
}

@media only screen and (max-width: 479px) {
  .experience-year .number {
    font-size: 85px;
  }
}

.about-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about-btns .theme-btn:not(:last-child) {
  margin-right: 25px;
}

.about-video-play i {
  font-size: 12px;
  margin-right: 15px;
  width: 50px;
  height: 50px;
  background: white;
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
  color: #0d6efd;
  -webkit-box-shadow: 10px 0 60px rgba(221, 29, 38, 0.26);
  box-shadow: 10px 0 60px rgba(221, 29, 38, 0.26);
}

.about-video-play span {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  text-decoration: underline;
}

/* About Two */
.about-two-feature {
  padding: 60px 90px 30px;
  -webkit-transform: translateY(-100px);
  -ms-transform: translateY(-100px);
  transform: translateY(-100px);
}

@media only screen and (max-width: 767px) {
  .about-two-feature {
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media only screen and (max-width: 375px) {
  .about-two-feature {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (max-width: 375px) {
  .global-client-part h3 {
    font-size: 25px;
  }
}

.global-client-part h3 span {
  color: #feb47b;
}

@media only screen and (max-width: 575px) {
  .global-client-part .global-clients .images img:not(:first-child) {
    margin-left: -30px;
  }
}

/* About Page */
.mission-vision-item {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-radius: 5px;
  padding: 30px 50px;
  border: 1px solid white;
  -webkit-box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
  box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
}

@media only screen and (min-width: 480px) {
  .mission-vision-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media only screen and (max-width: 575px) {
  .mission-vision-item {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.mission-vision-item .progress-content {
  line-height: 0;
  margin-right: 35px;
  position: relative;
}

@media only screen and (max-width: 479px) {
  .mission-vision-item .progress-content {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin-bottom: 15px;
  }
}

.mission-vision-item .progress-content i {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  text-align: center;
  width: calc(100% - 26px);
  height: calc(100% - 26px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: -webkit-gradient(linear, left top, right top, from(#ff7e5f), to(#feb47b));
  background: -webkit-linear-gradient(left, #ff7e5f, #feb47b);
  background: -o-linear-gradient(left, #ff7e5f, #feb47b);
  background: linear-gradient(90deg, #0d6efd, #0dcaf0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mission-vision-item .content p {
  margin-bottom: 0;
}

.mission-vision-item:not(:last-child) {
  margin-bottom: 30px;
}

.mission-vision-item:hover {
  border-color: #0d6efd;
}

.video-fact-counter {
  z-index: 1;
  position: relative;
  background-repeat: no-repeat;
  background-position: 100% 100%;
}

.video-fact-counter .circle-shapes {
  top: 55%;
  left: 16%;
  width: 27%;
  padding-top: 27%;
}

.about-page-video {
  margin-top: 290px;
  margin-bottom: -300px;
  -webkit-transform: translateY(-289px);
  -ms-transform: translateY(-289px);
  transform: translateY(-289px);
}

@media only screen and (max-width: 991px) {
  .about-page-video {
    margin-top: 100px;
    margin-bottom: -111px;
    -webkit-transform: translateY(-100px);
    -ms-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}

.about-page-video:before {
  opacity: 0.1;
}

.about-page-video .video-play2 {
  background: white;
  color: #0d6efd;
}

@media only screen and (min-width: 576px) {
  .about-page-video .video-play2 {
    width: 120px;
    height: 120px;
    font-size: 20px;
    line-height: 120px;
    border-radius: 50%;
    text-align: center;
  }
}

/* Printing Solutions */
.printing-solutions-image {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
}

.printing-solutions-image .circle-shapes {
  left: 45%;
}

/*******************************************************/
/*****************  11. What We Provide ****************/
/*******************************************************/
.what-we-provide {
  background-repeat: no-repeat;
  background-position: left bottom;
}

.what-we-provide-active {
  margin-left: -15px;
  margin-right: -15px;
}

.what-we-provide-active .what-we-provide-item {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 30px;
}

.what-we-provide-active .slick-dots {
  padding-top: 20px;
}

.what-we-provide-item {
  padding: 30px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: white;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 30px;
  z-index: 1;
  position: relative;
}

.what-we-provide-item::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0;
  background: -webkit-gradient(linear, left top, right top, from(#ff7e5f), to(#feb47b));
  background: -webkit-linear-gradient(left, #ff7e5f, #feb47b);
  background: -o-linear-gradient(left, #ff7e5f, #feb47b);
  background: linear-gradient(90deg, #0d6efd, #0dcaf0);
}

.what-we-provide-item:before {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-radius: 5px;
}

.what-we-provide-item i {
  line-height: 1;
  font-size: 75px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: 10px;
  display: inline-block;
  background: -webkit-gradient(linear, left top, right top, from(#ff7e5f), to(#feb47b));
  background: -webkit-linear-gradient(left, #ff7e5f, #feb47b);
  background: -o-linear-gradient(left, #ff7e5f, #feb47b);
  background: linear-gradient(90deg, #0d6efd, #0dcaf0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.what-we-provide-item h6 {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: 0;
  height: 3rem;
}

.what-we-provide-item:hover {
  -webkit-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  transform: translateY(-15px);
}

.what-we-provide-item:hover i,
.what-we-provide-item:hover h6,
.what-we-provide-item:hover h6 a {
  color: white;
}

.what-we-provide-item:hover i {
  -webkit-text-fill-color: white;
}

.what-we-provide-item:hover:before {
  opacity: 1;
}

.what-we-provide-item.hover-two:hover {
  margin-top: 15px;
}

/*******************************************************/
/******************  12. Services Area *****************/
/*******************************************************/
@media only screen and (min-width: 1600px) {
  .services-area {
    padding-left: 55px;
    padding-right: 55px;
  }
}

@media only screen and (max-width: 1599px) {
  .service-content {
    padding-right: 0;
    padding-left: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .service-content {
    padding-right: 50px;
  }
}

@media only screen and (max-width: 479px) {
  .service-content {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.service-content .sub-title {
  color: #43c1ab;
}

.service-content .circle-shapes:after,
.service-content .circle-shapes:before {
  display: none;
}

.circle-shapes.no-animation .shape-inner {
  -webkit-animation: none;
  animation: none;
}

.circlewrapper {
  position: relative;
  width: 100px;
  /* Adjust size as needed */
  height: 100px;
}

.circleper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-size: 27px;
  /* Adjust font size as needed */
  color: #000;
}

.service-item {
  z-index: 1;
  margin: -1px;
  position: relative;
  padding: 80px 70px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  height: 32rem
}

@media only screen and (max-width: 1599px) {
  .service-item {
    padding: 50px;
  }
}

@media only screen and (max-width: 479px) {
  .service-item {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.service-item .bg-image {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background-size: cover;
  z-index: 1;
  position: relative;
  position: absolute;
  z-index: -1;
}

.service-item .bg-image::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0.95;
  background: -webkit-gradient(linear, left top, right top, from(#ff7e5f), to(#feb47b));
  background: -webkit-linear-gradient(left, #ff7e5f, #feb47b);
  background: -o-linear-gradient(left, #ff7e5f, #feb47b);
  background: linear-gradient(90deg, #0d6efd, #0dcaf0);
}

.service-item .icon {
  line-height: 1;
  font-size: 65px;
  margin-bottom: 25px;
  background: -webkit-gradient(linear, left top, right top, from(#ff7e5f), to(#feb47b));
  background: -webkit-linear-gradient(left, #ff7e5f, #feb47b);
  background: -o-linear-gradient(left, #ff7e5f, #feb47b);
  background: linear-gradient(90deg, #0d6efd, #01d6e3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 375px) {
  .service-item .icon {
    font-size: 55px;
  }
}

.service-item h3 {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-bottom: 20px;
}

.service-item h3 a:hover {
  text-decoration: underline;
}

.service-item p {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-top: 15px;
}

.service-item:hover .bg-image {
  opacity: 1;
}

.service-item:hover p,
.service-item:hover h3,
.service-item:hover h3 a,
.service-item:hover .read-more,
.service-item:hover .read-more i {
  color: white;
}

.service-item:hover .icon {
  -webkit-text-fill-color: white;
}

.service-item.style-two {
  padding: 35px 30px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 375px) {
  .service-item.style-two {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.service-item.style-two .icon {
  font-size: 50px;
  margin-bottom: 12px;
}

.service-item.style-two h4 {
  font-size: 22px;
  margin-bottom: 0;
}

.service-item.style-two p {
  margin-bottom: 0;
}

.service-item.style-two:hover .icon {
  -webkit-text-fill-color: transparent;
}

.service-item.style-two:hover p {
  color: #b8b8b8;
}

.service-item.style-three {
  overflow: hidden;
  border-radius: 5px;
  padding: 40px 35px;
  margin-bottom: 30px;
}

.service-item.style-three .icon {
  font-size: 50px;
  margin-bottom: 15px;
}

.service-item.style-three h5 {
  font-size: 22px;
}

.service-item.style-three p {
  margin-bottom: 0;
}

/* Services Two */
.services-tab-area .tab-btns-one .nav-link {
  padding-top: 22px;
  padding-bottom: 22px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.services-tab-area .tab-btns-one .nav-link.active {
  border-bottom-color: #0d6efd;
}

@media only screen and (min-width: 1200px) {
  .service-two-image {
    margin-right: -30px;
  }
}

@media only screen and (min-width: 1200px) {
  .service-two-content {
    max-width: 390px;
    margin-left: auto;
  }
}

.service-two-content h3 {
  line-height: 1.43;
  margin-bottom: 20px;
}

@media only screen and (min-width: 480px) {
  .service-two-content h3 {
    font-size: 35px;
  }
}

/* Services Two */
.service-item-two {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 30px 50px 35px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 480px) {
  .service-item-two {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-item-two {
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media only screen and (max-width: 575px) {
  .service-item-two {
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media only screen and (max-width: 375px) {
  .service-item-two {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.service-item-two .icon {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  font-size: 66px;
  margin: 8px 30px 15px 0;
  background: -webkit-gradient(linear, left top, right top, from(#ff7e5f), to(#feb47b));
  background: -webkit-linear-gradient(left, #ff7e5f, #feb47b);
  background: -o-linear-gradient(left, #ff7e5f, #feb47b);
  background: linear-gradient(90deg, #0d6efd, #0dcaf0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 375px) {
  .service-item-two .icon {
    font-size: 50px;
  }
}

@media only screen and (max-width: 375px) {
  .service-item-two h3 {
    font-size: 22px;
  }
}

.service-item-two:hover,
.service-item-two.style-two {
  border-color: white;
  -webkit-box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
  box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
}

.service-item-two.style-two .content h4 {
  margin-bottom: 5px;
}

.service-item-two.style-two .content p {
  margin-bottom: 0;
}

/* Services Three */
.services-three-area {
  position: relative;
  z-index: 1;
}

.services-three-area:before {
  content: '';
  left: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 140%;
  position: absolute;
  background: #000000;
}

.service-item-three {
  border-radius: 7px;
  padding: 40px 30px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  text-align: center;
  margin-bottom: 30px;
  background: #0d0d0d;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.service-item-three .icon {
  padding: 15px 35px;
  border-radius: 50%;
  margin-bottom: 30px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.1);
}

.service-item-three h5 {
  font-size: 22px;
}

.service-item-three h5 a {
  color: white;
}

.service-item-three h5 a:hover {
  color: #feb47b;
  text-decoration: underline;
}

.service-item-three p {
  color: #b8b8b8;
  margin-bottom: 10px;
}

.service-item-three .read-more:hover {
  color: #feb47b;
}

.service-item-three .read-more:hover i {
  color: #feb47b;
}

.service-item-three:hover {
  border-color: #feb47b;
}

/* Services Four */
.services-four-slider {
  margin-left: -15px;
  margin-right: -15px;
}

.services-four-slider .service-item-four {
  margin-left: 15px;
  margin-right: 15px;
}

.services-four-slider .slick-dots {
  margin-top: 30px;
}

.service-item-four {
  text-align: center;
  margin-bottom: 30px;
}

.service-item-four .image img {
  width: 100%;
}

.service-item-four .content {
  padding: 35px 35px 40px;
  -webkit-box-shadow: 5px 5px 15px rgba(132, 132, 132, 0.1);
  box-shadow: 5px 5px 15px rgba(132, 132, 132, 0.1);
}

@media only screen and (max-width: 375px) {
  .service-item-four .content {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.service-item-four .content .theme-btn {
  width: 100%;
  margin-top: 10px;
}

/* Feature */
.feature-item {
  padding: 25px 40px;
  margin-bottom: 30px;
  -webkit-box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
  box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
}

@media only screen and (min-width: 376px) {
  .feature-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media only screen and (max-width: 479px) {
  .feature-item {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.feature-item .icon {
  font-size: 45px;
  color: #0d6efd;
  margin: 5px 22px 20px 0;
}

.feature-item .content h5 {
  font-size: 22px;
}

.feature-item .content p {
  margin-bottom: 0;
}

/* Why choose therr */
.why-choose-three {
  overflow: hidden;
}

.why-choose-three.text-white .sub-title {
  color: #feb47b;
}

.why-choose-three .circle-shapes {
  width: 50%;
  padding-top: 30%;
}

/* Services Page */
.circle-progress-item {
  margin-bottom: 30px;
}

.circle-progress-item h5 {
  margin: 10px 0 0;
  font-size: 18px;
}

.circle-progress-inner {
  position: relative;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.circle-progress-inner h3 {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.why-choose-services-images {
  position: relative;
  z-index: 1;
  padding: 15% 20% 10% 15%;
}

.why-choose-services-images .image-two {
  position: absolute;
  left: 0;
  top: 0;
  width: 30%;
}

.why-choose-services-images .image-three {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 42%;
}

.why-choose-services-images .circle-shapes {
  left: 40%;
}

/* Service Details */
.service-details-content .image img {
  width: 100%;
}

@media only screen and (min-width: 992px) {
  .service-details-content h3 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 375px) {
  .service-details-content h3 {
    font-size: 25px;
  }
}

.service-details-content .accordion-button {
  background: #f8f3ff;
}

.next-prev-post {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 991px) {
  .next-prev-post {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.next-prev-post .prev-post,
.next-prev-post .next-post {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: -20px;
  margin-right: -20px;
}

@media only screen and (max-width: 479px) {

  .next-prev-post .prev-post,
  .next-prev-post .next-post {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.next-prev-post .prev-post .image,
.next-prev-post .prev-post .content,
.next-prev-post .next-post .image,
.next-prev-post .next-post .content {
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (max-width: 479px) {

  .next-prev-post .prev-post .image,
  .next-prev-post .prev-post .content,
  .next-prev-post .next-post .image,
  .next-prev-post .next-post .content {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.next-prev-post .prev-post h5,
.next-prev-post .next-post h5 {
  margin-bottom: 5px;
}

@media only screen and (max-width: 375px) {

  .next-prev-post .prev-post .image,
  .next-prev-post .next-post .image {
    max-width: 45%;
  }
}

.next-prev-post .next-post {
  text-align: right;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.next-prev-post .show-all {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 30px;
  margin-top: 35px;
  margin-bottom: 35px;
  color: #0d6efd;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.next-prev-post .show-all i {
  margin: -10px 0;
}

/*******************************************************/
/******************  13. Counter Area ******************/
/*******************************************************/
.counter-item {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: white;
  padding: 40px 30px;
  border-radius: 7px;
  margin-bottom: 30px;
  border: 1px solid white;
  -webkit-box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
  box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
}

.counter-item .icon {
  line-height: 1;
  margin-bottom: 15px;
  color: #0d6efd;
}

.counter-item p {
  font-size: 20px;
  margin-bottom: 25px;
  height: 4.5rem;
}

.counter-item .count-text {
  line-height: 1;
  font-size: 48px;
  font-weight: 500;
  display: inline-block;
  padding-bottom: 38px;
  position: relative;
  color: #000000;
}

.counter-item .count-text.k-plus:after {
  content: 'k+';
}

.counter-item .count-text.plus:after {
  content: '+';
}

.counter-item .count-text:before {
  position: absolute;
  content: '';
  left: 0;
  bottom: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  width: 70px;
  height: 2px;
  background: #e7e7e7;
}

.counter-item:hover {
  border-color: #0d6efd;
}

.counter-item:hover .count-text:before {
  background: #43c1ab;
}

.counter-item.style-two {
  overflow: hidden;
  background: transparent;
  border-color: rgba(255, 255, 255, 0.1);
  z-index: 1;
  position: relative;
}

.counter-item.style-two::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0;
  background: -webkit-gradient(linear, left top, right top, from(#ff7e5f), to(#feb47b));
  background: -webkit-linear-gradient(left, #ff7e5f, #feb47b);
  background: -o-linear-gradient(left, #ff7e5f, #feb47b);
  background: linear-gradient(90deg, #0d6efd, #0dcaf0);
}

.counter-item.style-two:before {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.counter-item.style-two:after {
  content: '';
  width: 70%;
  opacity: 0;
  z-index: -1;
  right: 30px;
  bottom: 25px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding-top: 70%;
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.12);
}

.counter-item.style-two .icon,
.counter-item.style-two .count-text {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: -webkit-gradient(linear, left top, right top, from(#ff7e5f), to(#feb47b));
  background: -webkit-linear-gradient(left, #ff7e5f, #feb47b);
  background: -o-linear-gradient(left, #ff7e5f, #feb47b);
  background: linear-gradient(90deg, #0d6efd, #0dcaf0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.counter-item.style-two .count-text:before {
  background: -webkit-gradient(linear, left top, right top, from(#ff7e5f), to(#feb47b));
  background: -webkit-linear-gradient(left, #ff7e5f, #feb47b);
  background: -o-linear-gradient(left, #ff7e5f, #feb47b);
  background: linear-gradient(90deg, #0d6efd, #0dcaf0);
}

.counter-item.style-two p {
  color: white;
}

.counter-item.style-two:hover:after,
.counter-item.style-two:hover:before {
  opacity: 1;
}

.counter-item.style-two:hover .icon,
.counter-item.style-two:hover .count-text {
  -webkit-text-fill-color: white;
}

.counter-item.style-two:hover .count-text:before {
  background: white;
}

/* Team Pege Counter */
.team-page-counter .faq-counter {
  margin-top: 0;
  margin-bottom: 0;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

/*******************************************************/
/******************  14. Project Area ******************/
/*******************************************************/
.project-active {
  padding-right: 100px;
}

@media only screen and (max-width: 479px) {
  .project-active {
    padding-right: 0;
  }
}

.project-active .project-item .content {
  opacity: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
  -webkit-transform: translateY(-25px);
  -ms-transform: translateY(-25px);
  transform: translateY(-25px);
}

.project-active .project-item.slick-active .content {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.project-active .slick-arrow {
  right: -65px;
  font-size: 18px;
  overflow: hidden;
  width: 65px;
  height: 65px;
  background: white;
  line-height: 65px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 1;
  position: relative;
  position: absolute;
}

.project-active .slick-arrow::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0;
  background: -webkit-gradient(linear, left top, right top, from(#ff7e5f), to(#feb47b));
  background: -webkit-linear-gradient(left, #ff7e5f, #feb47b);
  background: -o-linear-gradient(left, #ff7e5f, #feb47b);
  background: linear-gradient(90deg, #0d6efd, #0dcaf0);
}

@media only screen and (max-width: 1399px) {
  .project-active .slick-arrow {
    right: 0;
  }
}

@media only screen and (max-width: 479px) {
  .project-active .slick-arrow {
    width: 45px;
    height: 45px;
    line-height: 45px;
  }

  .project-item1 {
    position: relative;
    width: 90% !important;
    margin: 0 auto;
  }
}

.project-active .slick-arrow:before {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.project-active .slick-arrow:hover,
.project-active .slick-arrow:focus {
  color: white;
}

.project-active .slick-arrow:hover:before,
.project-active .slick-arrow:focus:before {
  opacity: 1;
}

.project-active .slick-arrow.project-prev {
  top: calc(50% + 8px);
}

@media only screen and (max-width: 479px) {
  .project-active .slick-arrow.project-prev {
    top: 15px;
    right: 70px;
  }
}

.project-active .slick-arrow.project-next {
  bottom: calc(50% + 8px);
}

@media only screen and (max-width: 479px) {
  .project-active .slick-arrow.project-next {
    top: 15px;
    right: 15px;
  }

  .patcheswork {
    width: 20rem !important;
  }
}

/*service project class*/
.project-item1 {
  position: relative;
  margin: 0 auto;
  width: 100%;
  padding-inline: 1rem;
}

.patcheswork {
  height: auto !important;
  width: 14rem !important;
}

.project-item1 .content {
  position: relative;
  width: 100%;
  left: 10%;
  bottom: 0px;
  background: white;
  padding: 10px;
  height: 18rem;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.project-item1 .content img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.project-item {
  position: relative;
  padding: 5rem 7rem;
}

.project-item img {
  width: 100%;
  object-fit: cover;
}

.project-item .content {
  position: absolute;
  width: 80%;
  left: 10%;
  bottom: 40px;
  background: white;
  padding: 20px 35px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 479px) {
  .project-item .content {
    left: 5%;
    width: 90%;
    bottom: 20px;
  }

  .project-item1 .content {
    height: 37rem;
  }

  .project-item {
    position: relative;
    padding: 6rem 1rem;
  }

}

@media only screen and (max-width: 375px) {
  .project-item .content {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.project-item .content .text h4 {
  margin-bottom: 0;
}

@media only screen and (max-width: 575px) {

  .project-item .content .text h4,
  .project-item1 .content .text h4 {
    font-size: 20px;
  }
}

.project-item .content .text span,
.project-item1 .content .text span {
  font-size: 16px;
}

.project-item .content .plus,
.project-item1 .content .plus {
  color: white;
  width: 45px;
  height: 45px;
  background: #0d6efd;
  line-height: 45px;
  border-radius: 50%;
  text-align: center;
}

.project-tab-content .circle-shapes {
  left: 40%;
}

/* Project Style Two */
@media only screen and (min-width: 1400px) {
  .project-page-area {
    padding-left: 55px;
    padding-right: 55px;
  }
}

.project-item-two {
  padding: 20px;
  margin-bottom: 30px;
  -webkit-box-shadow: 10px 0 60px rgba(0, 0, 0, 0.07);
  box-shadow: 10px 0 60px rgba(0, 0, 0, 0.07);
  height: 16rem;
}

@media only screen and (max-width: 375px) {
  .project-item-two {
    padding: 10px;
  }
}

.project-item-two img {
  width: 100%;
  height: 100%;
  object-fit: initial;
}

.project-item-two .project-content {
  margin-bottom: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.project-item-two .project-content h5 {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) {
  .project-item-two .project-content h5 {
    font-size: 22px;
  }
}

.project-item-two .project-content span {
  font-size: 16px;
}

.project-item-two .project-content .plus {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 45px;
  height: 45px;
  line-height: 44px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.project-item-two .project-content .plus:focus,
.project-item-two .project-content .plus:hover {
  color: white;
  background: #0d6efd;
  border-color: #0d6efd;
}

.related-project-active {
  margin-left: -15px;
  margin-right: -15px;
}

.related-project-active .project-item-two {
  margin-top: 30px;
  margin-left: 15px;
  margin-right: 15px;
  -webkit-box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
}

.related-project-active .slick-dots {
  margin-top: 30px;
}

/* Project Filter */
.project-filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.project-filter li {
  cursor: pointer;
  font-weight: 600;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding: 5px 30px;
  margin: 0 5px 10px;
  border-radius: 5px;
  color: #000000;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 479px) {
  .project-filter li {
    font-size: 16px;
  }
}

@media only screen and (max-width: 479px) {
  .project-filter li {
    padding: 2px 20px;
  }
}

.project-filter li:hover,
.project-filter li.current {
  color: white;
  background: #0d6efd;
  border-color: #0d6efd;
}

/* Project Details */
.project-details-area .image img {
  width: 100%;
}

@media only screen and (min-width: 480px) {
  .project-details-area .content {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.project-description {
  z-index: 2;
  position: relative;
  border-radius: 7px;
  padding: 60px 70px;
  margin-bottom: -90px;
  background: #0d6efd;
}

@media only screen and (max-width: 991px) {
  .project-description {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 479px) {
  .project-description {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.project-description ul li:not(:last-child) {
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.project-description ul li span {
  color: white;
}

.project-description ul li h3 {
  color: white;
  margin-bottom: 0;
}

@media only screen and (max-width: 375px) {
  .project-description ul li h3 {
    font-size: 24px;
  }
}

/* Footer top projects */
.footer-top-projects {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media only screen and (max-width: 479px) {
  .footer-top-projects {
    margin-left: -5px;
    margin-right: -5px;
  }
}

.footer-top-projects .footer-project-item {
  margin-top: 30px;
  position: relative;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(20% - 30px);
}

@media only screen and (max-width: 1199px) {
  .footer-top-projects .footer-project-item {
    width: calc(25% - 30px);
  }
}

@media only screen and (max-width: 991px) {
  .footer-top-projects .footer-project-item {
    width: calc(33.3333% - 30px);
  }
}

@media only screen and (max-width: 767px) {
  .footer-top-projects .footer-project-item {
    width: calc(50% - 30px);
  }
}

@media only screen and (max-width: 479px) {
  .footer-top-projects .footer-project-item {
    margin: 10px 5px 0;
    width: calc(50% - 10px);
  }
}

.footer-top-projects .footer-project-item img {
  width: 100%;
}

.footer-top-projects .footer-project-item .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 479px) {
  .footer-top-projects .footer-project-item .content {
    padding: 15px;
    text-align: center;
  }
}

.footer-top-projects .footer-project-item .content h6 {
  color: white;
  margin-bottom: 0;
  padding: 5px 15px;
  border-radius: 5px;
  background: -webkit-gradient(linear, left top, right top, from(#ff7e5f), to(#feb47b));
  background: -webkit-linear-gradient(left, #ff7e5f, #feb47b);
  background: -o-linear-gradient(left, #ff7e5f, #feb47b);
  background: linear-gradient(90deg, #0d6efd, #0dcaf0);
}

@media only screen and (max-width: 479px) {
  .footer-top-projects .footer-project-item .content h6 {
    font-size: 16px;
  }
}

.footer-top-projects .footer-project-item .content h6 a {
  color: white;
}

.footer-top-projects .footer-project-item:hover .content {
  opacity: 1;
}

/*******************************************************/
/********************  15. CTA Area ********************/
/*******************************************************/
.cta-area {
  overflow: hidden;
}

.cta-content {
  max-width: 400px;
  margin-left: auto;
}

@media only screen and (min-width: 1600px) {
  .cta-content {
    margin-right: 65px;
  }
}

@media only screen and (max-width: 1199px) {
  .cta-content {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 479px) {
  .cta-content {
    margin-left: 35px;
    margin-right: 35px;
  }
}

@media only screen and (max-width: 375px) {
  .cta-content {
    margin-left: 25px;
    margin-right: 25px;
  }
}

.cta-left-image {
  max-width: 100%;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.cta-left-image .circle-shapes {
  left: 55%;
  top: 80%;
}

.cta-right-image {
  max-width: 100%;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

@media only screen and (max-width: 1199px) {
  .cta-right-image {
    margin-left: auto;
  }
}

.cta-right-image .circle-shapes {
  left: 35%;
  top: 65%;
}

.circle-shapes.white-shape:after,
.circle-shapes.white-shape:before {
  border-color: rgba(255, 255, 255, 0.3);
}

.circle-shapes.white-shape .shape-inner span {
  background: white;
}

/* CTA Two */
.cta-two-area {
  overflow: hidden;
}

.cta-two-area .circle-shapes:after,
.cta-two-area .circle-shapes:before {
  border-color: rgba(255, 255, 255, 0.15);
}

.cta-two-content .sub-title {
  color: #feb47b;
}

.cta-two-content .list-style-one li:before {
  color: #feb47b;
  font-size: 22px;
}

.cta-two-content .circle-shapes {
  top: 100%;
  left: 15%;
}

@media only screen and (min-width: 992px) {
  .cta-two-image {
    margin-right: -210px;
  }
}

.cta-two-image .circle-shapes {
  top: 75%;
}

/*******************************************************/
/***************  16. Testimonials Area ****************/
/*******************************************************/
.global-clients {
  padding: 15px 40px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 479px) {
  .global-clients {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.global-clients h4 {
  font-size: 20px;
  margin-bottom: 16px;
}

.global-clients .images {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.global-clients .images img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

@media only screen and (max-width: 479px) {
  .global-clients .images img {
    width: 50px;
    height: 50px;
  }
}

.global-clients .images img:not(:first-child) {
  margin-left: -20px;
}

@media only screen and (max-width: 375px) {
  .global-clients .images img:not(:first-child) {
    margin-left: -25px;
  }
}

.global-clients .images:after {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  content: '+';
  color: white;
  font-size: 30px;
  margin-left: -30px;
  width: 60px;
  height: 60px;
  background: #0d6efd;
  line-height: 60px;
  border-radius: 50%;
  text-align: center;
}

@media only screen and (max-width: 479px) {
  .global-clients .images:after {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}

.text-white .global-clients {
  border-color: rgba(255, 255, 255, 0.08);
}

.testimonial-item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (min-width: 992px) {
  .testimonial-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.testimonial-item .image {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 100%;
  margin-right: 8%;
}

@media only screen and (max-width: 991px) {
  .testimonial-item .image {
    width: 100%;
    margin-right: 0;
    margin-bottom: 45px;
  }
}

.testimonial-item .content .text {
  color: black;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.67;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .testimonial-item .content .text {
    font-size: 20px;
  }
}

.testimonial-item .content .text:after,
.testimonial-item .content .text:before {
  font-size: 18px;
  content: '\f116';
  display: inline-block;
  font-family: 'Flaticon';
}

.testimonial-item .content .text:before {
  margin-right: 10px;
  -webkit-transform: rotate(8deg) translateY(-15px);
  -ms-transform: rotate(8deg) translateY(-15px);
  transform: rotate(8deg) translateY(-15px);
}

.testimonial-item .content .text:after {
  margin-left: 10px;
  -webkit-transform: rotate(190deg) translateY(-20px);
  -ms-transform: rotate(190deg) translateY(-20px);
  transform: rotate(190deg) translateY(-20px);
}

.testimonial-item .content .author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.testimonial-item .content .author img {
  width: 65px;
  /* height: 65px; */
  height: auto;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  /* border-radius: 50%; */
  border-radius: 0%;
  margin-right: 20px;
}

.testimonial-item .content .author .title span {
  font-size: 16px;
}

.testimonial-item .content .author .title span {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0;
  color: #0d6efd;
}

.testimonial-active {
  padding-right: 250px;
}

@media only screen and (max-width: 1199px) {
  .testimonial-active {
    padding-right: 150px;
  }
}

@media only screen and (max-width: 479px) {
  .testimonial-active {
    padding-right: 0;
  }
}

@media only screen and (min-width: 992px) {
  .testimonial-active .testimonial-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.testimonial-active .testimonial-item .image {
  opacity: 0;
  -webkit-transition: 0.95s;
  -o-transition: 0.95s;
  transition: 0.95s;
  -webkit-transform: translate(45px);
  -ms-transform: translate(45px);
  transform: translate(45px);
}

@media only screen and (max-width: 991px) {
  .testimonial-active .testimonial-item .image {
    -webkit-transform: translate(0, 45px);
    -ms-transform: translate(0, 45px);
    transform: translate(0, 45px);
  }
}

@media only screen and (max-width: 479px) {
  .testimonial-active .testimonial-item .image img {
    width: 100%;
  }
}

.testimonial-active .testimonial-item .content {
  opacity: 0;
  -webkit-transition: 0.95s;
  -o-transition: 0.95s;
  transition: 0.95s;
  -webkit-transform: translate(-45px);
  -ms-transform: translate(-45px);
  transform: translate(-45px);
}

@media only screen and (max-width: 991px) {
  .testimonial-active .testimonial-item .content {
    -webkit-transform: translate(0, -45px);
    -ms-transform: translate(0, -45px);
    transform: translate(0, -45px);
  }
}

.testimonial-active .testimonial-item.slick-active .image,
.testimonial-active .testimonial-item.slick-active .content {
  opacity: 1;
  -webkit-transform: translate(0);
  -ms-transform: translate(0);
  transform: translate(0);
}

.testimonial-active .slick-arrow {
  right: 0;
  font-size: 18px;
  overflow: hidden;
  width: 65px;
  height: 65px;
  background: white;
  line-height: 65px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 1;
  position: relative;
  position: absolute;
}

.testimonial-active .slick-arrow::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0;
  background: -webkit-gradient(linear, left top, right top, from(#ff7e5f), to(#feb47b));
  background: -webkit-linear-gradient(left, #ff7e5f, #feb47b);
  background: -o-linear-gradient(left, #ff7e5f, #feb47b);
  background: linear-gradient(90deg, #0d6efd, #0dcaf0);
}

@media only screen and (max-width: 479px) {
  .testimonial-active .slick-arrow {
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
}

.testimonial-active .slick-arrow:before {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.testimonial-active .slick-arrow:hover,
.testimonial-active .slick-arrow:focus {
  color: white;
}

.testimonial-active .slick-arrow:hover:before,
.testimonial-active .slick-arrow:focus:before {
  opacity: 1;
}

.testimonial-active .slick-arrow.testimonial-prev {
  top: calc(50% + 8px);
}

@media only screen and (max-width: 479px) {
  .testimonial-active .slick-arrow.testimonial-prev {
    top: 15px;
    right: 70px;
  }
}

.testimonial-active .slick-arrow.testimonial-next {
  bottom: calc(50% + 8px);
}

@media only screen and (max-width: 479px) {
  .testimonial-active .slick-arrow.testimonial-next {
    top: 15px;
    right: 15px;
  }
}

.testimonial-active:after {
  content: '';
  top: 0;
  z-index: -1;
  right: 130px;
  width: 1px;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 1199px) {
  .testimonial-active:after {
    right: 100px;
  }
}

@media only screen and (max-width: 479px) {
  .testimonial-active:after {
    display: none;
  }
}

.testimonials-area .circle-shapes {
  left: 100px;
  top: 75%;
  width: 400px;
  padding-top: 400px;
}

/* Testimonial Two */
.testimonial-two-item {
  text-align: center;
}

.testimonial-two-item .image {
  width: 100px;
  height: 100px;
  margin-bottom: 30px;
  border-radius: 50%;
  display: inline-block;
  border: 15px solid rgba(20, 17, 37, 0.07);
}

.testimonial-two-item .image img {
  border-radius: 50%;
}

.testimonial-two-item p {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.6;
  color: #000000;
}

@media only screen and (max-width: 375px) {
  .testimonial-two-item p {
    font-size: 20px;
  }
}

.testimonial-two-item .quote {
  font-size: 80px;
  margin-top: 25px;
  margin-bottom: 15px;
  background: -webkit-gradient(linear, left top, right top, from(#ff7e5f), to(#feb47b));
  background: -webkit-linear-gradient(left, #ff7e5f, #feb47b);
  background: -o-linear-gradient(left, #ff7e5f, #feb47b);
  background: linear-gradient(90deg, #0d6efd, #0dcaf0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.testimonial-two-item .name {
  font-size: 22px;
  margin-bottom: 0;
}

.testimonial-two-item .designation {
  font-size: 16px;
}

.testimonial-two-item.style-two {
  padding: 40px;
  background: white;
  margin-bottom: 30px;
}

@media only screen and (max-width: 375px) {
  .testimonial-two-item.style-two {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.testimonial-two-item.style-two .image {
  margin-bottom: 10px;
}

.testimonial-two-item.style-two p {
  font-size: 20px;
}

@media only screen and (max-width: 375px) {
  .testimonial-two-item.style-two p {
    font-size: 18px;
  }
}

.testimonial-middle-image {
  position: relative;
  text-align: center;
  z-index: 1;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.testimonial-middle-image img {
  width: 90%;
}

.testimonial-middle-image:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 180px;
  -webkit-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  transform: rotate(10deg);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.testimonial-two-dots .slick-dots {
  left: 50%;
  bottom: -85px;
  position: absolute;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
}

.testimonial-left-slider .slick-dots {
  margin-top: 35px;
  margin-bottom: 35px;
}

/* Testimonials Three */
.testimonials-three-area {
  position: relative;
  z-index: 1;
}

.testimonials-three-area:before {
  position: absolute;
  width: 505px;
  height: 505px;
  content: '';
  left: 5%;
  bottom: 50px;
  border-radius: 50%;
  background: -webkit-gradient(linear, left top, right top, from(#ff7e5f), to(#feb47b));
  background: -webkit-linear-gradient(left, #ff7e5f, #feb47b);
  background: -o-linear-gradient(left, #ff7e5f, #feb47b);
  background: linear-gradient(90deg, #0d6efd, #0dcaf0);
}

@media only screen and (max-width: 767px) {
  .testimonials-three-area:before {
    left: -15%;
    width: 350px;
    height: 350px;
    bottom: 100px;
  }
}

.testimonials-three-area .circle-shapes {
  left: 85%;
  width: 615px;
  padding-top: 615px;
}

@media only screen and (max-width: 767px) {
  .testimonials-three-area .circle-shapes {
    top: 35%;
    width: 400px;
    padding-top: 400px;
  }
}

.testimonial-three-slider {
  margin-left: -15px;
  margin-right: -15px;
}

.testimonial-three-slider .testimonial-two-item {
  margin-left: 15px;
  margin-right: 15px;
}

.testimonial-three-slider .slick-dots {
  margin-top: 30px;
}

/*******************************************************/
/******************  17. Pricing Area ******************/
/*******************************************************/
#page-banner {
  background-size: 100% 80%;
  background-repeat: no-repeat;
  height: 28rem;
}

.pricing-plan-item {
  padding: 25px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: white;
  text-align: center;
  margin-bottom: 30px;
  border: 1px solid white;
  -webkit-box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
  box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
}

@media only screen and (max-width: 375px) {
  .pricing-plan-item {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.pricing-plan-item:hover {
  border-color: #0d6efd;
}

.pricing-plan-item .title {
  line-height: 1.35;
  font-size: 24px;
  margin-bottom: 35px;
}

.pricing-plan-item .image {
  height: 0;
  padding-top: 80%;
  position: relative;
  margin-bottom: 0px;
}

.pricing-plan-item .image img {
  position: absolute;
  left: 50%;
  top: 33%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}

.pricing-plan-item ul {
  padding-bottom: 25px;
  height: 30rem;
  margin-top: 0rem;
}

.pricing-plan-item ul li {
  color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 15px;
  font-weight: 800;
  margin-bottom: 11px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pricing-plan-item ul li i {
  color: white;
  font-size: 12px;
  width: 25px;
  height: 25px;
  background: #0d6efd;
  line-height: 25px;
  border-radius: 50%;
  text-align: center;
}

.pricing-plan-item ul li.unavailable i {
  background: #0dcaf0;
}

.pricing-plan-item .price {
  color: black;
  display: block;
  font-size: 48px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 35px;
}

@media only screen and (max-width: 375px) {
  .pricing-plan-item .price {
    font-size: 38px;
  }
}

.pricing-plan-item .price:before {
  content: '$';
  font-size: 22px;
}

.pricing-plan-item .price:after {
  font-size: 16px;
  content: '/monthly';
  color: #7e7e7e;
}

/*******************************************************/
/*******************  18. Blog Area ********************/
/*******************************************************/
.blog-standardhigh {
  height: auto;
  overflow-y: scroll;
}

.blogdetailheight {
  height: 82rem;
  overflow-y: scroll;
}

.blog-standardhigh::-webkit-scrollbar,
.blogdetailheight::-webkit-scrollbar {
  width: 0em !important;
}

.blog-area .circle-shapes {
  width: 620px;
  padding-top: 620px;
  left: auto;
  right: 10%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

@media only screen and (max-width: 1199px) {
  .blog-area .circle-shapes {
    top: 70%;
  }
}

@media only screen and (max-width: 991px) {
  .blog-area .circle-shapes {
    width: 400px;
    padding-top: 400px;
  }
}

.blog-area:before {
  position: absolute;
  content: '';
  width: 400px;
  height: 400px;
  background: white;
  border-radius: 50%;
  top: 0%;
  left: 55%;
  z-index: -1;
}

@media only screen and (max-width: 1199px) {
  .blog-area:before {
    left: 10px;
  }

  #page-banner {
    background-size: 100% 63%;
    background-repeat: no-repeat;
    height: 20rem;
  }

  .banner-inner {
    margin-top: -1rem !important;
  }
}

.blog-item {
  margin-bottom: 30px;
}

.blog-item .image img {
  width: 100%;
  height: 16rem;
}

.blog-item .content {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  height: 14rem;
}

.blog-item .content .author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 7px 15px;
  background: white;
  border-radius: 5px;
  position: absolute;
  right: 20px;
  bottom: 100%;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  -webkit-box-shadow: 10px 0 60px rgba(221, 29, 38, 0.1);
  box-shadow: 10px 0 60px rgba(221, 29, 38, 0.1);
}

.blog-item .content .author img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 13px;
}

.blog-item .content .author .name {
  font-weight: 500;
}

.blog-item .content .author .name:not(:hover) {
  color: black;
}

.blog-item .content .date {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 6px;
  display: inline-block;
  text-transform: uppercase;
}

.blog-item .content .date i {
  margin-right: 10px;
}

.blog-item .content h4 {
  margin-bottom: 0;
}

@media only screen and (max-width: 375px) {
  .blog-item .content h4 {
    font-size: 20px;
  }
}

.blog-item .content h4 a:hover {
  color: #000000;
  text-decoration: underline;
}

.blog-item .content .read-more {
  opacity: 0;
  margin-top: 18px;
  visibility: hidden;
  color: #0d6efd;
}

.blog-item:hover .content,
.blog-item.active .content {
  background: white;
  padding-left: 35px;
  padding-right: 35px;
}

@media only screen and (max-width: 375px) {

  .blog-item:hover .content,
  .blog-item.active .content {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.blog-item:hover .content .read-more,
.blog-item.active .content .read-more {
  opacity: 1;
  visibility: visible;
}

.blog-item.style-two .content {
  padding-left: 0;
  padding-right: 0;
}

.blog-item.style-two .content h5 {
  line-height: 1.5;
}

.blog-item.style-two .content .read-more {
  opacity: 1;
  margin-top: 0;
  visibility: visible;
}

.blog-item.style-two .content .read-more:not(:hover) {
  color: #7e7e7e;
}

/* Realted News Slider */
.related-news-slider {
  margin-left: -15px;
  margin-right: -15px;
}

.related-news-slider .blog-item {
  margin: 0 15px 20px;
}

/* Blog Style Two */
.blog-slider {
  margin-left: -15px;
  margin-right: -15px;
}

.blog-slider .blog-item-two {
  margin-left: 15px;
  margin-right: 15px;
}

@media only screen and (min-width: 480px) {
  .blog-slider .blog-item-two {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.blog-slider .slick-dots {
  margin-top: 30px;
}

.blog-item-two {
  padding: 30px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: white;
  position: relative;
  border-radius: 7px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 480px) {
  .blog-item-two {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media only screen and (max-width: 375px) {
  .blog-item-two {
    padding: 22px;
  }

  .pricing-plan-item ul {
    height: auto;
  }
}

.blog-item-two .image {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}

@media only screen and (min-width: 480px) {
  .blog-item-two .image {
    width: 36%;
    margin-right: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .blog-item-two .image {
    margin-bottom: 20px;
  }

  .pricing-plan-item ul {
    height: auto !important;
  }
}

.blog-item-two .image img {
  width: 100%;
}

.blog-item-two .content .category {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  line-height: 1;
  font-size: 14px;
  padding: 6px 8px;
  font-weight: 600;
  border-radius: 5px;
  background: #feb47b;
  text-transform: uppercase;
}

@media only screen and (max-width: 479px) {
  .blog-item-two .content .category {
    top: 40px;
    right: 40px;
  }
}

.blog-item-two .content .date {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 6px;
  display: inline-block;
  text-transform: uppercase;
}

.blog-item-two .content .date i {
  font-size: 15px;
  margin-right: 5px;
}

@media only screen and (max-width: 575px) {
  .blog-item-two .content h4 {
    font-size: 20px;
  }
}

.blog-item-two .content .read-more {
  color: #0d6efd;
}

.blog-item-two:hover {
  background: #f8f3ff;
  border-color: #f8f3ff;
}

.blog-item-two:hover .category {
  background: #0d6efd;
}

/* Blog Standard */
.blog-standard-item,
.blog-details-content {
  margin-bottom: 30px;
}

.blog-standard-item .image img,
.blog-details-content .image img {
  width: 100%;
}

.blog-standard-item .content,
.blog-details-content .content {
  padding: 30px 50px;
  -webkit-box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
  box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
}

@media only screen and (max-width: 767px) {

  .blog-standard-item .content,
  .blog-details-content .content {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 479px) {

  .blog-standard-item .content,
  .blog-details-content .content {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.blog-standard-item .content .blog-meta,
.blog-details-content .content .blog-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 10px;
}

.blog-standard-item .content .blog-meta li,
.blog-details-content .content .blog-meta li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 5px;
}

@media only screen and (max-width: 767px) {

  .blog-standard-item .content .blog-meta li,
  .blog-details-content .content .blog-meta li {
    font-size: 14px;
  }
}

.blog-standard-item .content .blog-meta li i,
.blog-details-content .content .blog-meta li i {
  margin-right: 10px;
}

@media only screen and (max-width: 575px) {

  .blog-standard-item .content .blog-meta li i,
  .blog-details-content .content .blog-meta li i {
    display: none;
  }
}

.blog-standard-item .content .blog-meta li:not(:last-child),
.blog-details-content .content .blog-meta li:not(:last-child) {
  margin-right: 22px;
}

@media only screen and (max-width: 767px) {

  .blog-standard-item .content .blog-meta li:not(:last-child),
  .blog-details-content .content .blog-meta li:not(:last-child) {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 375px) {

  .blog-standard-item .content .blog-meta li:not(:last-child),
  .blog-details-content .content .blog-meta li:not(:last-child) {
    margin-right: 10px;
  }
}

.blog-standard-item .content .blog-meta li:not(:last-child):after,
.blog-details-content .content .blog-meta li:not(:last-child):after {
  content: '';
  width: 5px;
  height: 5px;
  margin-left: 22px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 767px) {

  .blog-standard-item .content .blog-meta li:not(:last-child):after,
  .blog-details-content .content .blog-meta li:not(:last-child):after {
    margin-left: 15px;
  }
}

@media only screen and (max-width: 375px) {

  .blog-standard-item .content .blog-meta li:not(:last-child):after,
  .blog-details-content .content .blog-meta li:not(:last-child):after {
    margin-left: 10px;
  }

  #page-banner {
    background-size: 100% 40%;
    background-repeat: no-repeat;
    height: 15.5rem;
  }

  .banner-inner {
    margin-top: 1.5rem !important;
  }

  .banner-inner .page-title {
    margin-bottom: 10px !important;
  }
}

.blog-standard-item .content .blog-meta li:first-child:after,
.blog-details-content .content .blog-meta li:first-child:after {
  display: none;
}

.blog-standard-item .content .blog-meta li:not(:first-child),
.blog-details-content .content .blog-meta li:not(:first-child) {
  text-transform: uppercase;
}

.blog-standard-item .content .blog-meta li .author,
.blog-details-content .content .blog-meta li .author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 6px 15px;
  background: white;
  border-radius: 5px;
  -webkit-box-shadow: 10px 0 60px rgba(132, 132, 132, 0.25);
  box-shadow: 10px 0 60px rgba(132, 132, 132, 0.25);
}

.blog-standard-item .content .blog-meta li .author img,
.blog-details-content .content .blog-meta li .author img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 13px;
}

@media only screen and (max-width: 575px) {

  .blog-standard-item .content .blog-meta li .author img,
  .blog-details-content .content .blog-meta li .author img {
    display: none;
  }
}

.blog-standard-item .content .blog-meta li .author .name,
.blog-details-content .content .blog-meta li .author .name {
  font-size: 18px;
  font-weight: 500;
  color: #000000;
}

.blog-standard-item .content h4,
.blog-details-content .content h4 {
  font-size: 26px;
}

@media only screen and (max-width: 767px) {

  .blog-standard-item .content h4,
  .blog-details-content .content h4 {
    font-size: 22px;
    line-height: 1.5;
  }
}

@media only screen and (max-width: 479px) {

  .blog-standard-item .content h4,
  .blog-details-content .content h4 {
    font-size: 20px;
  }

  #page-banner {
    background-size: 100% 40%;
    background-repeat: no-repeat;
    height: 15.5rem;
  }

  .banner-inner {
    margin-top: 1.5rem !important;
  }
}

@media only screen and (max-width: 375px) {

  .blog-standard-item .content h4,
  .blog-details-content .content h4 {
    font-size: 18px;
  }
}

.blog-standard-item.text-white .read-more,
.blog-standard-item.text-white .read-more i,
.blog-details-content.text-white .read-more,
.blog-details-content.text-white .read-more i {
  color: white;
}

.blog-standard-item.text-white .blog-meta li:not(:last-child):after,
.blog-details-content.text-white .blog-meta li:not(:last-child):after {
  background: white;
}

.blog-standard-item.text-white.bgc-gradient li a,
.blog-details-content.text-white.bgc-gradient li a {
  color: white;
}

.blog-standard-item.text-white.bgc-gradient li:after,
.blog-details-content.text-white.bgc-gradient li:after {
  background: white;
}

.blog-standard-item.text-white.bg-image li i,
.blog-details-content.text-white.bg-image li i {
  color: #b8b8b8;
}

/* Blog Details */
.blog-details-content .blog-title {
  margin-bottom: 22px;
}

.blog-details-content blockquote {
  font-weight: 500;
  text-align: center;
  color: #000000;
  padding: 20px 60px 10px;
}

@media only screen and (min-width: 376px) {
  .blog-details-content blockquote {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-content blockquote {
    padding-left: 0;
    padding-right: 0;
  }
}

.blog-details-content blockquote .icon {
  font-size: 48px;
  margin-bottom: 10px;
  background: -webkit-gradient(linear, left top, right top, from(#ff7e5f), to(#feb47b));
  background: -webkit-linear-gradient(left, #ff7e5f, #feb47b);
  background: -o-linear-gradient(left, #ff7e5f, #feb47b);
  background: linear-gradient(90deg, #0d6efd, #0dcaf0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blog-details-content .tag-share li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 2px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-details-content .tag-share li h6 {
  margin-right: 10px;
}

.blog-details-content .tag-share li .tags {
  margin-bottom: 12px;
}

.blog-details-content .tag-share li .tags a:not(:last-child):after {
  content: ',';
  margin-right: 3px;
}

.blog-details-content .tag-share li .social-style-two {
  margin-bottom: 12px;
}

@media only screen and (min-width: 480px) {
  .admin-comment {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.admin-comment .image {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  max-width: 120px;
  margin-right: 30px;
}

@media only screen and (max-width: 479px) {
  .admin-comment .image {
    margin-bottom: 15px;
  }
}

.admin-comment .image img {
  border-radius: 50%;
}

.admin-comment p,
.admin-comment .name {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) {
  .blog-subtitle {
    font-size: 30px;
  }
}

/* Blog Comment */
.comment-body {
  margin-right: 30px;
  margin-bottom: 30px;
}

@media only screen and (min-width: 480px) {
  .comment-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media only screen and (max-width: 375px) {
  .comment-body {
    margin-right: 0;
  }
}

.comment-body .author-thumb {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  max-width: 80px;
  margin: 25px 20px 25px 0;
}

.comment-body .author-thumb img {
  border-radius: 50%;
}

.comment-body .comment-content {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-radius: 5px;
  padding: 25px 30px;
  border-radius: 5px;
  position: relative;
  background: #f8f3ff;
}

.comment-body .comment-content:before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  top: 25px;
  right: 100%;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-bottom: 15px solid transparent;
  border-right: 15px solid #f8f3ff;
}

@media only screen and (max-width: 479px) {
  .comment-body .comment-content:before {
    top: auto;
    left: 25px;
    right: auto;
    bottom: 100%;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.comment-body .comment-content h6 {
  margin-bottom: 0;
}

.comment-body .comment-content .date {
  font-size: 16px;
}

.comment-body .comment-content p {
  margin-bottom: 0;
}

.comment-body .comment-content .reply-link {
  font-size: 16px;
  font-weight: 500;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-decoration: underline;
}

.comment-body .comment-content .reply-link i {
  float: right;
  margin-top: 3px;
  margin-left: 6px;
}

.comment-body:hover .comment-content {
  background: white;
  -webkit-box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
  box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
}

.comment-body:hover .comment-content:before {
  border-right-color: white;
}

.children .comment-body {
  margin-right: 0;
  margin-left: 30px;
}

@media only screen and (max-width: 375px) {
  .children .comment-body {
    margin-left: 15px;
  }
}

.comment-form .form-control::-webkit-input-placeholder {
  font-weight: 400;
  color: #7e7e7e;
}

.comment-form .form-control:-ms-input-placeholder {
  font-weight: 400;
  color: #7e7e7e;
}

.comment-form .form-control::-ms-input-placeholder {
  font-weight: 400;
  color: #7e7e7e;
}

.comment-form .form-control::placeholder {
  font-weight: 400;
  color: #7e7e7e;
}

.comment-form .form-control:focus {
  background: #f8f3ff;
  border-color: #f8f3ff;
}

/*******************************************************/
/*******************  19. Shop Area ********************/
/*******************************************************/
.product-item {
  margin-bottom: 30px;
}

.product-item .image {
  position: relative;
}

.product-item .image img {
  width: 100%;
}

.product-item .image .badge {
  position: absolute;
  left: 10px;
  top: 10px;
  font-weight: 600;
  border-radius: 5px;
  background: #43c1ab;
}

.product-item .image .badge.red {
  background: #0d6efd;
}

.product-item .image .badge.pink {
  background: #ea00ff;
}

.product-item .content {
  z-index: 1;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: white;
  position: relative;
  padding: 16px 25px 20px;
  -webkit-box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
  box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
}

.product-item .content .title-price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.product-item .content .title-price h5 {
  margin-bottom: 0;
}

.product-item .content .title-price .price {
  font-weight: 600;
  color: #0d6efd;
}

.product-item .content .title-price .price:before {
  content: '$';
}

.product-item .content .social-style-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  opacity: 0;
  left: 0;
  z-index: -1;
  bottom: 20px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.product-item .content .social-style-two a {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.product-item:hover .content {
  margin-top: -55px;
  padding-bottom: 75px;
}

.product-item:hover .content .social-style-two {
  opacity: 1;
}

.product-item.style-two {
  max-width: 830px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  .product-item.style-two {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.product-item.style-two .image {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}

@media only screen and (min-width: 768px) {
  .product-item.style-two .image {
    max-width: 40%;
    margin-right: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .product-item.style-two .image {
    margin-bottom: 20px;
  }
}

.product-item.style-two .product-content h3 {
  margin-bottom: 0;
}

.product-item.style-two .product-content .price {
  font-weight: 600;
  margin-bottom: 15px;
  color: #0d6efd;
}

.product-item.style-two .product-content .price:before {
  content: '$';
}

.product-item.style-two .product-content .social-style-two {
  margin-bottom: -10px;
}

.product-item.style-two .product-content .social-style-two a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 9px;
  padding-right: 9px;
  margin-bottom: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.product-item.style-two .product-content .social-style-two a span {
  color: white;
  display: none;
  padding-left: 15px;
  padding-right: 15px;
}

.product-item.style-two .product-content .social-style-two a:hover {
  width: auto;
  border-radius: 5px;
  border-color: #0d6efd;
}

.product-item.style-two .product-content .social-style-two a:hover span {
  display: block;
}

.product-item.style-two .product-content .social-style-two a:hover span~i {
  display: none;
}

/* Shop Advertisement */
.shop-advertisement {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: calc(100% - 30px);
}

@media only screen and (max-width: 1199px) {
  .shop-advertisement {
    height: auto;
    margin-bottom: 30px;
  }
}

.shop-advertisement .content {
  padding: 35px;
}

.shop-advertisement .content h3 {
  font-size: 35px;
}

.shop-advertisement .image {
  z-index: 1;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
  margin-top: auto;
  position: relative;
}

.shop-advertisement .image:after {
  content: '';
  width: 120%;
  opacity: 0.15;
  padding-top: 120%;
  border-radius: 50%;
  background: white;
  z-index: -1;
  left: 0;
  bottom: 15%;
  position: absolute;
}

.shop-advertisement .circle-shapes {
  top: 20%;
  width: 130%;
  padding-top: 130%;
}

/* Priduct Style Two */
.product-two-slider {
  margin-left: -15px;
  margin-right: -15px;
}

.product-two-slider .product-item-two {
  margin-left: 15px;
  margin-right: 15px;
}

.product-two-slider .slick-dots {
  margin-top: 15px;
}

.product-item-two {
  padding: 10px;
  margin-bottom: 30px;
  background: #f8f3ff;
}

.product-item-two .image {
  margin-bottom: 18px;
}

.product-item-two .image img {
  width: 100%;
  border-radius: 5px;
}

.product-item-two .content {
  padding: 0 8px 10px;
}

.product-item-two .content .title-price {
  position: relative;
  padding-right: 35px;
  padding-bottom: 10px;
}

.product-item-two .content .title-price .category {
  font-size: 14px;
}

.product-item-two .content .title-price .price {
  position: absolute;
  right: 0;
  top: 2px;
  font-weight: 600;
  color: #0d6efd;
}

.product-item-two .content .title-price .price:before {
  content: '$';
}

.product-item-two .content .theme-btn {
  width: 100%;
  line-height: 1.75;
}

.product-item-two .content .theme-btn:not(:hover) {
  background: white;
}

/* Shop Shorter */
.default-search-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 12px 20px;
  border-radius: 5px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.default-search-form input {
  padding: 0;
  border: none;
  font-size: 16px;
}

.default-search-form input::-webkit-input-placeholder {
  font-weight: 400;
  color: #7e7e7e;
}

.default-search-form input:-ms-input-placeholder {
  font-weight: 400;
  color: #7e7e7e;
}

.default-search-form input::-ms-input-placeholder {
  font-weight: 400;
  color: #7e7e7e;
}

.default-search-form input::placeholder {
  font-weight: 400;
  color: #7e7e7e;
}

.default-search-form button {
  font-size: 16px;
  background: transparent;
}

.shop-shorter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.shop-shorter .default-search-form {
  margin-right: 30px;
  margin-bottom: 15px;
}

.shop-shorter .sort-text {
  margin-right: auto;
  margin-bottom: 15px;
}

.shop-shorter .products-dropdown {
  margin-right: 25px;
  margin-bottom: 15px;
}

.shop-shorter .products-dropdown .nice-select {
  font-size: 16px;
  font-weight: 600;
  padding: 12px 55px 12px 25px;
}

.shop-shorter .products-dropdown .nice-select .current {
  color: #000000;
}

.shop-shorter .products-dropdown .nice-select:after {
  width: 7px;
  height: 7px;
  right: 20px;
  border-color: #000000;
}

.shop-shorter .grid-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}

.shop-shorter .grid-list li:not(:last-child) {
  margin-right: 20px;
}

.shop-shorter .grid-list li a {
  font-size: 30px;
  color: #000000;
}

/* Product Details */
.preview-item img {
  width: 100%;
}

.thumb-images .thumb-item {
  margin-bottom: 30px;
}

.thumb-images .thumb-item img {
  width: 100%;
}

.thumb-images.style-two {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.thumb-images.style-two .thumb-item {
  width: calc(33.333% - 20px);
}

@media only screen and (min-width: 1200px) {
  .product-details-content {
    margin-left: 30px;
  }
}

@media only screen and (min-width: 768px) {
  .product-details-content h2 {
    font-size: 38px;
  }
}

.ratting-price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ratting-price .ratting {
  padding-right: 10px;
}

.ratting-price .ratting i {
  font-size: 13px;
  margin-right: 6px;
}

.ratting-price .price {
  font-size: 24px;
  font-weight: 600;
  color: #000000;
}

.ratting-price .price:before {
  content: '$';
}

.category-tags li:not(:last-child) {
  margin-bottom: 5px;
}

.category-tags li b {
  width: 90px;
  color: #000000;
  display: inline-block;
}

@media only screen and (max-width: 375px) {
  .category-tags li b {
    width: auto;
    margin-right: 6px;
  }
}

.category-tags li b+span {
  margin-right: 15px;
}

@media only screen and (max-width: 375px) {
  .category-tags li b+span {
    margin-right: 6px;
  }
}

.category-tags li a {
  font-size: 16px;
}

.category-tags li a:not(:last-child) {
  margin-right: 2px;
}

.category-tags li a:not(:last-child):after {
  content: ',';
}

.add-to-cart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.add-to-cart input {
  width: 95px;
  padding: 10px 22px;
  margin-right: 10px;
  margin-top: 10px;
}

.add-to-cart .theme-btn {
  margin-top: 10px;
  margin-right: 10px;
}

.add-to-cart .wishlist {
  margin-top: 10px;
  width: 60px;
  height: 50px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.product-information-tab {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.product-information-tab li a {
  font-size: 24px;
  font-weight: 600;
  padding: 18px 35px;
  margin-bottom: -1px;
  color: #000000;
  display: inline-block;
  border-bottom: 1px solid transparent;
}

@media only screen and (max-width: 991px) {
  .product-information-tab li a {
    font-size: 20px;
    padding: 12px 25px;
  }
}

@media only screen and (max-width: 375px) {
  .product-information-tab li a {
    font-size: 16px;
    padding: 8px 22px;
  }
}

.product-information-tab li a.active {
  color: #0d6efd;
  border-color: #0d6efd;
}

@media only screen and (min-width: 768px) {
  .review-form-area h3 {
    font-size: 30px;
  }
}

.review-form-area .ratting {
  margin-bottom: 10px;
}

.review-form-area .ratting h5 {
  margin: 0 15px 0 0;
}

.review-form-area .nice-select:not(:focus),
.review-form-area .form-control:not(:focus) {
  background: #f8f3ff;
  border-color: #f8f3ff;
}

.review-form-area .nice-select .current,
.review-form-area .form-control::-webkit-input-placeholder {
  font-weight: 400;
  color: #7e7e7e;
}

.review-form-area .nice-select .current,
.review-form-area .form-control:-ms-input-placeholder {
  font-weight: 400;
  color: #7e7e7e;
}

.review-form-area .nice-select .current,
.review-form-area .form-control::-ms-input-placeholder {
  font-weight: 400;
  color: #7e7e7e;
}

.review-form-area .nice-select .current,
.review-form-area .form-control::placeholder {
  font-weight: 400;
  color: #7e7e7e;
}

/* Product Details Two */
.product-info-accordion .product-accordion-item:not(:last-child) {
  margin-bottom: 25px;
}

.product-info-accordion .accordion-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.product-info-accordion .accordion-header button {
  font-weight: 600;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  color: #0d6efd;
  padding: 0 70px 25px 0;
  background: transparent;
  border-bottom: 1px solid #0d6efd;
}

.product-info-accordion .accordion-header button.collapsed {
  border-color: transparent;
  color: #000000;
}

.product-info-accordion .accordion-content {
  padding-top: 22px;
}

@media only screen and (max-width: 575px) {
  .product-info-accordion .accordion-content {
    font-size: 16px;
  }
}

/* Related Product */
.realated-product-slider {
  margin-left: -15px;
  margin-right: -15px;
}

.realated-product-slider .product-item {
  margin-left: 15px;
  margin-right: 15px;
}

.realated-product-slider .slick-dots {
  margin-top: 15px;
}

/*******************************************************/
/******************  20. All Widgets *******************/
/*******************************************************/
/* Blog Sidebar */
.blog-sidebar .widget:not(:last-child) {
  margin-bottom: 60px;
}



.blog-sidebar .widget-title {
  margin-top: -10px;
  position: relative;
  margin-bottom: 35px;
  padding-bottom: 22px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.blog-sidebar .widget-title:after {
  content: '';
  left: 0;
  bottom: 0;
  width: 85px;
  height: 1px;
  position: absolute;
  background: #0d6efd;
}

.blog-sidebar .widget-search {
  padding: 40px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.blog-sidebar .widget-search .widget-title {
  padding-bottom: 0;
  margin-bottom: 20px;
  border-bottom: none;
}

.blog-sidebar .widget-search .widget-title:after {
  display: none;
}

@media only screen and (max-width: 375px) {
  .blog-sidebar .widget-search {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.blog-sidebar .widget-search form {
  padding-top: 15px;
  padding-bottom: 15px;
  background: #f8f3ff;
  border-color: #f8f3ff;
}

.blog-sidebar .widget-search form input {
  background: transparent;
}

.blog-sidebar .widget-category ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 17px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.blog-sidebar .widget-category ul li:before {
  content: '';
  width: 5px;
  height: 5px;
  opacity: 0.25;
  background: black;
  border-radius: 50%;
  margin-right: 15px;
}

.blog-sidebar .widget-category ul li:not(:last-child) {
  margin-bottom: 17px;
}

.blog-sidebar .widget-category ul li a {
  margin-right: auto;
}

.blog-sidebar .widget-category ul li span {
  margin-left: 5px;
}

.blog-sidebar .widget-recent-post ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.blog-sidebar .widget-recent-post ul li:not(:last-child) {
  margin-bottom: 30px;
}

.blog-sidebar .widget-recent-post ul li .image {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  max-width: 100px;
  margin-right: 30px;
}

@media only screen and (max-width: 375px) {
  .blog-sidebar .widget-recent-post ul li .image {
    max-width: 90px;
    margin-right: 20px;
  }
}

.blog-sidebar .widget-recent-post ul li .content h6 {
  margin-bottom: 2px;
}

@media only screen and (max-width: 375px) {
  .blog-sidebar .widget-recent-post ul li .content h6 {
    font-size: 16px;
  }
}

.blog-sidebar .widget-recent-post ul li .content .admin {
  font-size: 16px;
}

.blog-sidebar .widget-recent-post ul li .content .admin a {
  color: #0d6efd;
}

.blog-sidebar .widget-tag-cloud .tags a {
  font-size: 16px;
  padding: 5px 20px;
}

/* Service Sidebar */

.service-sidebar .widget:not(:last-child) {
  margin-bottom: 50px;
}

.service-sidebar .widget-title {
  margin-bottom: 30px;
}

.service-sidebar .widget-category ul li:not(:last-child) {
  margin-bottom: 10px;
}

.service-sidebar .widget-category ul li a {
  font-weight: 500;
  padding: 13px 30px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.service-sidebar .widget-category ul li a:after {
  content: '\f178';
  font-family: 'Font Awesome 5 Pro';
}

.service-sidebar .widget-category ul li a:hover {
  color: white;
  background: #0d6efd;
  border-color: #0d6efd;
}

.service-sidebar .widget-download {
  border-radius: 5px;
  padding: 40px 35px;
  background: #000000;
}

.service-sidebar .widget-download .widget-title {
  color: white;
  margin-bottom: 25px;
}

.service-sidebar .widget-download .widget-title:before {
  color: white;
}

.service-sidebar .widget-download .theme-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #b8b8b8;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-color: rgba(255, 255, 255, 0.2);
}

.service-sidebar .widget-download .social-style-two a {
  width: 35px;
  height: 35px;
  color: white;
  font-size: 14px;
  overflow: hidden;
  line-height: 35px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.15);
  z-index: 1;
  position: relative;
}

.service-sidebar .widget-download .social-style-two a::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0;
  background: -webkit-gradient(linear, left top, right top, from(#ff7e5f), to(#feb47b));
  background: -webkit-linear-gradient(left, #ff7e5f, #feb47b);
  background: -o-linear-gradient(left, #ff7e5f, #feb47b);
  background: linear-gradient(90deg, #0d6efd, #0dcaf0);
}

.service-sidebar .widget-download .social-style-two a:before {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.service-sidebar .widget-download .social-style-two a:hover:before {
  opacity: 1;
}

.service-sidebar .widget-cta {
  z-index: 1;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  text-align: center;
  padding: 40px 33px 30px;
  background: -webkit-gradient(linear, left top, right top, from(#ff7e5f), to(#feb47b));
  background: -webkit-linear-gradient(left, #ff7e5f, #feb47b);
  background: -o-linear-gradient(left, #ff7e5f, #feb47b);
  background: linear-gradient(90deg, #0d6efd, #0dcaf0);
}

@media only screen and (max-width: 375px) {
  .service-sidebar .widget-cta {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.service-sidebar .widget-cta * {
  color: white;
}

.service-sidebar .widget-cta .h5 {
  font-size: 20px;
  margin-bottom: 0;
}

.service-sidebar .widget-cta h3 {
  line-height: 1.2;
}

.service-sidebar .widget-cta h6 {
  font-size: 16px;
  margin-bottom: 20px;
}

.service-sidebar .widget-cta:before {
  content: '';
  width: 80%;
  z-index: -1;
  right: -5%;
  bottom: 30px;
  padding-top: 80%;
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
}

.before-circle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.before-circle:before {
  margin-top: 2px;
  content: '\f2f8';
  font-weight: 300;
  font-size: 0.95em;
  margin-right: 15px;
  color: #0d6efd;
  font-family: 'Font Awesome 5 Pro';
}

/* Shop Sidebar */
.shop-sidebar .widget:not(:last-child) {
  margin-bottom: 45px;
}

.shop-sidebar .widget-title {
  z-index: 1;
  position: relative;
  margin-bottom: 25px;
  padding-bottom: 18px;
}

.shop-sidebar .widget-title:after,
.shop-sidebar .widget-title:before {
  height: 3px;
  content: '';
  bottom: 0;
  z-index: -1;
  position: absolute;
  border-radius: 1.5px;
  background: #0d6efd;
}

.shop-sidebar .widget-title:before {
  left: 0;
  width: 35px;
}

.shop-sidebar .widget-title:after {
  left: 42px;
  width: 10px;
}

.shop-sidebar .widget-search form {
  padding: 8px 8px 8px 20px;
}

.shop-sidebar .widget-search form input {
  font-size: 18px;
}

.shop-sidebar .widget-search form button {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 44px;
  height: 44px;
  color: white;
  line-height: 44px;
  text-align: center;
  border-radius: 5px;
  background: #0d6efd;
}

.shop-sidebar .widget-category ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.shop-sidebar .widget-category ul li:before {
  content: '\f101';
  margin-right: 12px;
  font-family: 'Font Awesome 5 Pro';
}

.shop-sidebar .widget-category ul li:not(:last-child) {
  margin-bottom: 8px;
}

.shop-sidebar .widget-category ul li a {
  margin-right: auto;
}

.shop-sidebar .widget-category ul li span {
  margin-left: 5px;
}

.shop-sidebar .widget-products ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.shop-sidebar .widget-products ul li:not(:last-child) {
  margin-bottom: 30px;
}

.shop-sidebar .widget-products ul li .image {
  margin-right: 25px;
}

.shop-sidebar .widget-products ul li .content h5 {
  margin-bottom: 0;
}

.shop-sidebar .widget-products ul li .content .price {
  font-size: 16px;
  font-weight: 500;
}

.shop-sidebar .widget-price-filter {
  padding: 30px;
  border-radius: 5px;
  -webkit-box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
  box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
}

.shop-sidebar .widget-price-filter .ui-widget.ui-widget-content {
  height: 3px;
  border: none;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 5px;
  background: #e7e8eb;
}

.shop-sidebar .widget-price-filter .ui-widget-header {
  background: #0d6efd;
}

.shop-sidebar .widget-price-filter .ui-state-default {
  border: none;
  top: -6px;
  margin-left: 0;
  cursor: pointer;
  width: 3px;
  height: 15px;
  background: #0d6efd;
}

.shop-sidebar .widget-price-filter .price,
.shop-sidebar .widget-price-filter #price {
  font-size: 18px;
  font-weight: 500;
}

.shop-sidebar .widget-price-filter .price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.shop-sidebar .widget-price-filter .price span {
  margin-right: 5px;
}

.shop-sidebar .widget-price-filter .price span:before {
  content: '$';
}

.shop-sidebar .widget-price-filter #price {
  padding: 0;
  border: none;
  margin-left: 5px;
  color: #7e7e7e;
  background: transparent;
}

.widget-tag-cloud .tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -10px -10px 0;
}

.widget-tag-cloud .tags a {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  padding: 3px 15px;
  border-radius: 5px;
  color: #000000;
  margin: 0 10px 10px 0;
  background: #f8f3ff;
}

.widget-tag-cloud .tags a:hover {
  color: white;
  background: #0d6efd;
}

/*******************************************************/
/******************  21. Category Area *****************/
/*******************************************************/
.cetagory-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 25px 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
  border-radius: 7px;
  border: 1px solid rgba(255, 255, 255, 0.15);
}

@media only screen and (max-width: 575px) {
  .cetagory-item {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.cetagory-item img {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 100px;
  height: 100px;
  margin-right: 30px;
  border-radius: 50%;
}

@media only screen and (max-width: 479px) {
  .cetagory-item img {
    width: 65px;
    height: 65px;
    margin-right: 15px;
  }
}

.cetagory-item h3 {
  margin-bottom: 0;
  margin-right: 15px;
}

@media only screen and (max-width: 575px) {
  .cetagory-item h3 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 375px) {
  .cetagory-item h3 {
    font-size: 18px;
  }
}

.cetagory-item .details-btn {
  width: 50px;
  height: 50px;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  text-align: center;
  margin-left: auto;
  line-height: 50px;
  border-radius: 5px;
  background: #101010;
}

.cetagory-item .details-btn i {
  color: white;
}

.cetagory-item:hover .details-btn {
  background: #0d6efd;
}

.category-circle-shape {
  position: relative;
  margin-top: -15px;
  margin-bottom: 30px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.category-circle-shape .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 45px;
  background: -webkit-gradient(linear, left top, right top, from(#ff7e5f), to(#feb47b));
  background: -webkit-linear-gradient(left, #ff7e5f, #feb47b);
  background: -o-linear-gradient(left, #ff7e5f, #feb47b);
  background: linear-gradient(90deg, #0d6efd, #0dcaf0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  -ms-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

.category-circle-shape img {
  -webkit-animation: rotated_circle_two 50s infinite;
  animation: rotated_circle_two 50s infinite;
}

.category-more-btn {
  width: 135px;
  height: 135px;
  overflow: hidden;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 1;
  position: relative;
}

.category-more-btn::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 1;
  background: -webkit-gradient(linear, left top, right top, from(#ff7e5f), to(#feb47b));
  background: -webkit-linear-gradient(left, #ff7e5f, #feb47b);
  background: -o-linear-gradient(left, #ff7e5f, #feb47b);
  background: linear-gradient(90deg, #0d6efd, #0dcaf0);
}

.category-more-btn:before {
  -webkit-animation: rotated_circle_two 50s infinite;
  animation: rotated_circle_two 50s infinite;
}

.category-more-btn i {
  margin-bottom: 10px;
  color: white !important;
}

.category-more-btn span {
  font-size: 16px;
  line-height: 1.75;
}

.category-more-btn:hover span {
  text-decoration: underline;
}

/*******************************************************/
/******************  22. Offers Area *******************/
/*******************************************************/
@media only screen and (min-width: 1600px) {
  .offer-banners {
    padding-left: 55px;
    padding-right: 55px;
  }
}

.offer-banner-item {
  padding: 50px;
  margin-bottom: 30px;
  background-size: cover;
  height: calc(100% - 30px);
  background-position: center;
}

@media only screen and (max-width: 375px) {
  .offer-banner-item {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.offer-banner-item .content {
  margin-left: auto;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
}

.offer-banner-item .content .off {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 10px;
  color: #000000;
}

@media only screen and (min-width: 1400px) {
  .offer-banner-item .content h3 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .offer-banner-item .content h3 {
    font-size: 25px;
  }
}

.offer-banner-item .content .theme-btn {
  margin-top: 55px;
}

.offer-banner-item .content .theme-btn:not(:hover) {
  background: white;
}

/* Special Offer */
.count-down-wrap {
  overflow: hidden;
  padding-left: 50px;
  padding-right: 50px;
}

@media only screen and (min-width: 992px) {
  .count-down-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media only screen and (max-width: 375px) {
  .count-down-wrap {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.special-offer-left {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  max-width: 100%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 992px) {
  .special-offer-left {
    width: 26%;
  }
}

.special-offer-left .circle-shapes {
  left: 44%;
}

@media only screen and (min-width: 376px) {
  .special-offer-left .circle-shapes {
    width: 110%;
    padding-top: 110%;
  }
}

.special-offer-left .circle-shapes:after,
.special-offer-left .circle-shapes:before {
  -webkit-animation: none;
  animation: none;
}

.special-offer-right {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  z-index: 1;
  max-width: 100%;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  -ms-flex-item-align: self-end;
  -ms-grid-row-align: self-end;
  align-self: self-end;
}

@media only screen and (min-width: 992px) {
  .special-offer-right {
    width: 24%;
  }
}

.special-offer-right:before {
  content: '';
  width: 110%;
  z-index: -1;
  left: -5%;
  top: -5%;
  padding-top: 110%;
  position: absolute;
  background: -webkit-gradient(linear, left top, right top, from(#ff7e5f), to(#feb47b));
  background: -webkit-linear-gradient(left, #ff7e5f, #feb47b);
  background: -o-linear-gradient(left, #ff7e5f, #feb47b);
  background: linear-gradient(90deg, #0d6efd, #0dcaf0);
  border-radius: 55% 45% 50% 50%;
  -webkit-animation: 10s border-radius infinite;
  animation: 10s border-radius infinite;
}

@media only screen and (min-width: 768px) {
  .count-down-content {
    padding-left: 45px;
    padding-right: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .count-down-content .section-title h4 {
    font-size: 38px;
  }
}

.count-down {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 15px;
  font-weight: 500;
  background: white;
  border-radius: 7px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 25px 0 5px;
  text-transform: capitalize;
}

.count-down li {
  width: 25%;
  position: relative;
  margin-bottom: 20px;
}

@media only screen and (max-width: 575px) {
  .count-down li {
    width: 50%;
  }

  .count-down li:nth-child(2n):after {
    display: none;
  }
}

.count-down li:after {
  content: ':';
  right: 0;
  top: 50%;
  font-size: 30px;
  font-weight: 600;
  position: absolute;
  color: #000000;
  -webkit-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
}

.count-down li:last-child:after {
  display: none;
}

.count-down li span {
  display: block;
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #0d6efd;
}

/*******************************************************/
/********************  23. Work Step *******************/
/*******************************************************/
.work-process-item {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: white;
  border-radius: 7px;
  text-align: center;
  margin-bottom: 30px;
  border: 1px solid white;
  padding: 40px 30px 18px;
  -webkit-box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
  box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
}

.work-process-item .image {
  margin-bottom: 30px;
}

.work-process-item .step-number {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 6px;
  letter-spacing: 5px;
  color: #0d6efd;
  display: inline-block;
  text-transform: uppercase;
}

.work-process-item h5 {
  font-size: 22px;
}

.work-process-item.active {
  border-color: #0d6efd;
  -webkit-box-shadow: 10px 0 60px rgba(76, 76, 76, 0.2);
  box-shadow: 10px 0 60px rgba(76, 76, 76, 0.2);
}

@media only screen and (min-width: 768px) {
  .work-process-item.active {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

.work-process-area .container {
  position: relative;
  z-index: 1;
}

.work-process-area .container:after {
  content: '';
  left: 0;
  bottom: 0;
  z-index: -2;
  width: 100%;
  height: 47%;
  position: absolute;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.work-step-arrow-one,
.work-step-arrow-two {
  position: absolute;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  opacity: 0.1;
  bottom: 45%;
  z-index: -1;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.work-step-arrow-one:hover,
.work-step-arrow-two:hover {
  opacity: 1;
}

.work-step-arrow-one {
  left: 33.333%;
}

.work-step-arrow-two {
  left: 66.666%;
}

/* Work Step Two */
.work-process-two-wrap .work-step-arrow-one,
.work-process-two-wrap .work-step-arrow-two {
  max-width: 32px;
  opacity: 1;
}

.work-process-two-item {
  padding: 12px;
  background: white;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: auto;
  width: 12rem;
  margin-inline: auto;
}

.work-process-two-item .icon {
  font-size: 40px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin: 0 auto 10px;
  color: #0d6efd;
  width: 65px;
  height: 65px;
  background: #f8f3ff;
  line-height: 61px;
  border-radius: 50%;
  text-align: center;
}

.work-process-two-item .icon i:before {
  line-height: unset;
}

.work-process-two-item .step-number {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.work-process-two-item h5 {
  margin-bottom: 0;
  font-size: 1rem;
}

.work-process-two-item.active {
  border-color: #0d6efd;
}

.work-process-two-item.active .icon {
  color: white;
  background: #0d6efd;
}

/*******************************************************/
/********************  24. FAQ Area ********************/
/*******************************************************/
.faq-client-slider {
  background: white;
  text-align: center;
  -webkit-transform: translateY(-80px);
  -ms-transform: translateY(-80px);
  transform: translateY(-80px);
  -webkit-box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
  box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
}

@media only screen and (max-width: 575px) {
  .faq-client-slider {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.faq-client-item {
  margin-left: 15px;
  margin-right: 15px;
}

.faq-client-item img {
  display: inline-block;
}

.accordion-item {
  border: none;
  border-radius: 5px;
  -webkit-box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
  box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
}

.accordion-item:not(:last-child) {
  margin-bottom: 15px;
}

.accordion-item .accordion-button {
  font-weight: 600;
  color: #000000;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (min-width: 376px) {
  .accordion-item .accordion-button {
    font-size: 18px;
  }
}

.accordion-item .accordion-button:after {
  content: '\f070';
  font-weight: 400;
  margin-left: 5px;
  background-image: none;
  font-family: 'Font Awesome 5 Pro';
}

.accordion-item .accordion-button:focus {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
}

.accordion-item .accordion-button:not(.collapsed) {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #0d6efd;
  text-decoration: underline;
  background-color: transparent;
}

.accordion-item .accordion-button:not(.collapsed):after {
  float: right;
  content: '\f06e';
  color: #0d6efd;
}

.accordion-item .accordion-body {
  padding-top: 0;
  padding-bottom: 5px;
}

.accordion-item.style-two {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.accordion-item.style-two .accordion-button {
  border-radius: 5px;
  padding-top: 18px;
  padding-bottom: 18px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion-item.style-two .accordion-button:not(.collapsed) {
  border-color: white;
  -webkit-box-shadow: 10px 0 60px rgba(25, 28, 40, 0.08);
  box-shadow: 10px 0 60px rgba(25, 28, 40, 0.08);
}

.accordion-item.style-two .accordion-body {
  padding-top: 15px;
}

/* FAQ Counter */
.faq-counter {
  margin-bottom: -220px;
  padding: 80px 90px 50px;
  -webkit-transform: translateY(-220px);
  -ms-transform: translateY(-220px);
  transform: translateY(-220px);
}

@media only screen and (max-width: 479px) {
  .faq-counter {
    padding-left: 25px;
    padding-right: 25px;
  }
}

/*******************************************************/
/******************  25. Team Member *******************/
/*******************************************************/
.team-member {
  margin-bottom: 55px;
}

.team-member .image {
  margin-right: 20px;
}

.team-member .image img {
  width: 100%;
}

.team-member .content {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  max-width: 100%;
  margin-top: -50px;
  margin-left: auto;
  text-align: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 25px 30px;
  z-index: 1;
  position: relative;
}

.team-member .content::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 1;
  background: white;
}

.team-member .content:before {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-radius: 5px;
  height: calc(100% - 55px);
  -webkit-box-shadow: 10px 0 30px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 0 30px rgba(0, 0, 0, 0.1);
}

.team-member .content h5 {
  font-size: 22px;
  margin-bottom: 5px;
}

.team-member .content .designation {
  display: block;
  font-size: 16px;
  margin-bottom: 10px;
}

.team-member .content .social-style-two {
  opacity: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  visibility: hidden;
}

.team-member:hover .content {
  margin-top: -75px;
}

.team-member:hover .content:before {
  height: 100%;
}

.team-member:hover .content .social-style-two {
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.team-area .circle-shapes {
  top: 55%;
  left: 48%;
  width: 380px;
  padding-top: 380px;
}

.team-slider {
  margin-left: -35px;
  margin-right: -35px;
}

.team-slider .team-member {
  margin-left: 35px;
  margin-right: 35px;
}

/* Team Page */
.team-why-choose-images {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.team-why-choose-images .image-one {
  z-index: 1;
  max-width: 48%;
  position: relative;
  margin-right: -10%;
  border-radius: 10px;
}

.team-why-choose-images .image-two {
  max-width: 60%;
  margin-bottom: 20%;
  border-radius: 10px;
}

.team-page-area .circle-shapes {
  left: 48%;
  top: 500px;
  width: 380px;
  padding-top: 380px;
}

/*******************************************************/
/******************  26. Skills Area *******************/
/*******************************************************/
.skillbar-wrap {
  padding: 40px;
  border-radius: 5px;
  -webkit-box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
  box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
}

@media only screen and (max-width: 375px) {
  .skillbar-wrap {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.skillbar {
  height: 1px;
  margin-top: 100px;
  position: relative;
  background: rgba(0, 0, 0, 0.1);
}

.skillbar:first-child {
  margin-top: 60px;
}

.skillbar .skillbar-title,
.skillbar .skill-bar-percent {
  top: -50px;
  font-size: 18px;
  font-weight: 600;
  position: absolute;
}

.skillbar .skillbar-title {
  color: #000000;
}

.skillbar .skill-bar-percent {
  right: -20px;
  padding: 7px;
  color: white;
  line-height: 1;
  border-radius: 5px;
  background: #0d6efd;
}

.skillbar .skill-bar-percent:after {
  position: absolute;
  content: '';
  left: 10px;
  top: 100%;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-top: 10px solid #0d6efd;
}

.skillbar .skillbar-bar {
  width: 0;
  height: 3px;
  position: relative;
  overflow: visible !important;
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
  background-color: #0d6efd;
}

/*******************************************************/
/******************  27. Contact Area ******************/
/*******************************************************/
.faq-contact-form {
  padding: 60px 70px;
  border-radius: 7px;
  -webkit-box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
  box-shadow: 10px 0 60px rgba(132, 132, 132, 0.1);
}

@media only screen and (max-width: 575px) {
  .faq-contact-form {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.contact-info-wrap,
.contact-info-content {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.contact-info-content {
  padding: 75px 80px;
}

@media only screen and (min-width: 992px) {
  .contact-info-content {
    border-right: none;
  }
}

@media only screen and (max-width: 991px) {
  .contact-info-content {
    border-bottom: none;
  }
}

@media only screen and (max-width: 575px) {
  .contact-info-content {
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media only screen and (max-width: 375px) {
  .contact-info-content {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.contact-info-content p {
  margin-bottom: 0;
}

.contact-info-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 40px 80px;
}

@media only screen and (max-width: 575px) {
  .termsH {
    color: black !important;
  }

  .sm-mt {
    margin-top: 60px;
  }

  .contact-info-item {
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media only screen and (max-width: 375px) {
  .contact-info-item {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.contact-info-item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.contact-info-item .icon {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  color: white;
  margin-top: 5px;
  font-size: 25px;
  margin-right: 25px;
  width: 60px;
  height: 60px;
  background: #0d6efd;
  line-height: 60px;
  border-radius: 50%;
  text-align: center;
}

@media only screen and (max-width: 375px) {
  .contact-info-item .icon {
    width: 44px;
    height: 44px;
    font-size: 16px;
    line-height: 44px;
    margin-right: 15px;
  }
}

@media only screen and (min-width: 480px) {
  .contact-info-item .content {
    font-size: 20px;
  }
}

@media only screen and (max-width: 375px) {
  .contact-info-item .content {
    font-size: 16px;
    line-height: 1.5;
  }
}

.contact-info-item .content h4 {
  margin-bottom: 5px;
}

@media only screen and (max-width: 375px) {
  .contact-info-item .content h4 {
    font-size: 20px;
  }
}

.contact-info-item .content p {
  margin-bottom: 0;
}

.contact-info-item .content a {
  word-break: break-all;
}

.our-location iframe {
  height: 685px;
}

@media only screen and (max-width: 1399px) {
  .our-location iframe {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .our-location iframe {
    height: 400px;
  }
}

#contactForm .form-group label {
  position: absolute;
  z-index: 1;
  top: 13px;
  left: 0;
}

#contactForm .form-group .nice-select,
#contactForm .form-group .form-control {
  border: none;
  margin-right: 0;
  border-radius: 0;
  padding-left: 42px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

#contactForm .form-group .nice-select .list,
#contactForm .form-group .form-control .list {
  width: 100%;
}

#contactForm .form-group .nice-select .current,
#contactForm .form-group .nice-select::-webkit-input-placeholder,
#contactForm .form-group .form-control .current,
#contactForm .form-group .form-control::-webkit-input-placeholder {
  font-weight: 400;
  color: #7e7e7e;
}

#contactForm .form-group .nice-select .current,
#contactForm .form-group .nice-select:-ms-input-placeholder,
#contactForm .form-group .form-control .current,
#contactForm .form-group .form-control:-ms-input-placeholder {
  font-weight: 400;
  color: #7e7e7e;
}

#contactForm .form-group .nice-select .current,
#contactForm .form-group .nice-select::-ms-input-placeholder,
#contactForm .form-group .form-control .current,
#contactForm .form-group .form-control::-ms-input-placeholder {
  font-weight: 400;
  color: #7e7e7e;
}

#contactForm .form-group .nice-select .current,
#contactForm .form-group .nice-select::placeholder,
#contactForm .form-group .form-control .current,
#contactForm .form-group .form-control::placeholder {
  font-weight: 400;
  color: #7e7e7e;
}

#contactForm .form-group .nice-select:focus,
#contactForm .form-group .form-control:focus {
  border-bottom-color: #000000;
}

#contactForm .tac-wrap input {
  margin-right: 10px;
}

#contactForm .tac-wrap label {
  font-weight: 400;
  display: inline;
}

.has-error .help-block.with-errors {
  color: #0d6efd;
}

#msgSubmit {
  margin-top: 10px;
  margin-bottom: 0;
}

#msgSubmit.hidden {
  display: none;
}

/*******************************************************/
/*******************  28. 404 Error ********************/
/*******************************************************/
@media only screen and (min-width: 992px) {
  .error-page-content h2 {
    font-size: 45px;
  }
}

.error-page-content h2 span {
  font-size: 145%;
}

/*******************************************************/
/******************  29. Main Footer *******************/
/*******************************************************/
.footer-area {
  background-color: #000000 !important;
}

.footer-area .widget-title {
  font-size: 22px;
  margin-bottom: 30px;
  color: #ffff;
  line-height: 1.35;
}

.footer-area .widget {
  margin-bottom: 50px;
}

.footer-area .widget_nav_menu ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.footer-area .widget_nav_menu ul li {
  width: 50%;
  font-size: 16px;
}

@media only screen and (max-width: 375px) {
  .footer-area .widget_nav_menu ul li {
    width: 100%;
  }
}

.footer-area .widget_nav_menu ul li:not(:last-child) {
  margin-bottom: 8px;
}

.footer-area .widget_nav_menu ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
}

.footer-area .widget_nav_menu ul li a:hover {
  color: #0d6efd;
}

.footer-area .widget_nav_menu ul li a:before {
  content: '\f105';
  margin-right: 10px;
  font-family: 'Font Awesome 5 Pro';
}

.footer-area .widget_contact_info li {
  color: #FFFF;
}

.footer-area .widget_contact_info li:not(:last-child) {
  margin-bottom: 8px;
}

.footer-area .widget_contact_info li i {
  color: #0d6efd;
  margin-right: 10px;
}

.footer-area .widget_contact_info li a:not(:hover) {
  color: #FFFF;
}

.footer-bottom {
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer-bottom .copyright-text a {
  color: #0d6efd;
}

.hidden-bar::-webkit-scrollbar {
  width: 0em;
}

.hidden-bar::-webkit-scrollbar-thumb {
  background-color: transparent;
}

html::-webkit-scrollbar {
  width: .3em !important;
}

html::-webkit-scrollbar-thumb {
  background-color: #0d6efd;
}

#sideselect {
  display: block !important;
  background-color: #000000;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.form-group .nice-select {
  display: none;
}

/*model portfolio view*/
.modalConationer {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  background-color: #fff;
  position: fixed;
}

.modalConationer img {
  width: 100%;
  height: 100%;
  border-radius: 25px;
}

.modalConationer .close-btn {
  font-size: 2.5rem;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
}

.modalConationer .close-btn:hover {
  color: #0d6efd;
}

.blue-background {
  background-color: #0d6efd;
  color: #fff;
}

.colorbluew {
  color: #0d6efd;
}

.about-video .video-play1 {
  z-index: 2;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.video-play1 {
  color: white;
  font-size: 12px;
  display: inline-block;
  width: 60px;
  height: 60px;
  background: linear-gradient(90deg, #0d6efd, #0dcaf0);
  line-height: 60px;
  border-radius: 50%;
  text-align: center;
}

.modal {
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  background-color: #fff !important;
  padding: 20px !important;
  border-radius: 5px !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5) !important;
  z-index: 1050;
  /* Ensure the modal appears above the backdrop */
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5) !important;
  /* Semi-transparent background */
  z-index: 1040 !important;
  /* Ensure the backdrop appears below the modal */
}

.modal-backdrop.show {
  opacity: -0.5 !important;
}

.whatsapp-float {

  align-items: center;
  border-radius: 50%;
  bottom: 20px;
  box-shadow: 2px 2px 5px #0000004d;
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 10px;
  position: fixed;
  right: 20px;
  z-index: 1000;
  background-color: #25D366;

}

.whatsapp-float:hover {
  background-color: #25D366;
}

a.whatsapp-float:hover {
  color: white !important;
}

a.whatsapp-float svg:hover path {
  fill: white !important;
}


@media (max-width:1300px) and (min-width:1200px) {
  .navbar-area .navbar-collapse .navbar-nav>li {
    margin-right: 13px !important;
  }
}

.sidebarmenuright-container {
  position: fixed;
  right: -44px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  z-index: 1000;
}

.sidebarmenuright-container button {
  background-color: #0d6efd;
  border: 2px solid #c6c3cc;
  border-bottom: none;
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 5px 20px;
}

@media (max-width:768px) {
  .whatsapp-float {
    left: 8px;
    right: auto;
    bottom: 118px;
  }

  .about-images {
    display: none;
  }

  .hero-images .image-one {
    position: absolute;
    left: 10%;
    top: -20%;
    width: 85% !important;
  }

  .sps{
    padding-top: 202px;
  }
}

.awards {
  font-size: large;
}

.get_appointment {
  bottom: 24px;
  line-height: 1.35;
  font-size: x-large;
  font-weight: 600;
  color: #fff;
  margin-bottom: 35px;
  position: relative;
}

.projects h3 {
  line-height: 1.35;
  margin-bottom: 0;
}

.our-clients {
  text-transform: capitalize;
  font-size: 42px;
  line-height: 1.2;
}

.da-link:hover {
  text-decoration: underline;
}

@media only screen and (min-width: 480px) {
  .termsH {
    color: #fff;
  }
}

@media (max-width:500px) {
  .hero-images .image-one {
    position: absolute;
    left: 3%;
    top: -38%;
    width: 100% !important;
  }
  .sps{
    padding-top: 108px;
  }
}

@media (max-width:375px) {
  .sps{
    padding-top: 90px;
  }
}

@media (max-width:320px) {
  .sps{
    padding-top: 66px;
  }
}


.theme-btn.style-three:hover, button.theme-btn.style-three:hover {
  color: white;
  background: #0d6efd;
  border-color: #0d6efd;
}